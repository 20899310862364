import React from 'react';
import "./Aligi.css";
import img1 from "../Aligi/Assests/1.png";
import img2 from "../Aligi/Assests/8.png";
import img03 from "../Aligi/Assests/5.png";

const Aligi = () => {
  return (
    <div className='container'>
      <div className='row container11 py-5 my-5'>
        <div className='d-flex'>
          <div className='col-lg-6 content1 text-center'>
            <img src={img1} alt="" className="img1" />
            <h1 className="heading1">Unleash The Power of Generative AI for</h1>
            <h1 className="heading2"><span>Life Sciences Analytics</span></h1>
            <p className="paragraph1">
              Generative AI has democratized AI in a manner unseen previously. As Life Science companies grapple with increasing data volumes and complexity, they are seeking to use cognitive tools in various possibilities starting from fast drug discovery to efficient way of drug commercialization.
            </p>
            <button type="button" className="btn111">
              Contact
            </button>
          </div>
          <div className='col-lg-6 text-center'>
            <img src={img2} alt="" className="img2" />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12 d-flex justify-content-center align-items-start'>
          <div className='col-lg-6 text-center'>
            <img src={img03} alt="" className="img3" />
          </div>
          <div className='col-lg-6 text_center'>
            <h1 className="heading3">Come with an Idea and leave with a Solution!</h1>
            <p className="paragraph2">
              Agilisium Labs is hyper-focused on solving unique business problems by incubating new concepts and applying the latest technologies to deliver breakthrough solutions.
            </p>
            <p className="paragraph3">
              Agilisium Labs aims to become the preferred Data & Analytics Innovation partner for our clients. We focus on research and innovation on emergent technologies like Generative AI to deliver future forward solutions. Our innovators collaborate with internal stakeholders, leading academia, industry SMEs, and technology partners across the globe towards crafting solutions that are guided by our client’s needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aligi;
