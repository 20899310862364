import React from "react";
import Header from "../../Components/Header/Header";
import EnergyOverview from "../../Components/Industries/EnergyUtilizes/EnergyOverview/EnergyOverview";
import EnergyService from "../../Components/Industries/EnergyUtilizes/EnergyService/EnergyService";
import EnergyCaseStudies from "../../Components/Industries/EnergyUtilizes/EnergyCaseStudies/EnergyCaseStudies";
import EnergyConnect from "../../Components/Industries/EnergyUtilizes/EnergyConnect/EnergyConnect";
import Footer from "../../Components/Footer/Footer";

const Energy = () => {
  return (
    <>
      <Header />
      <EnergyOverview />
      <EnergyService />
      <EnergyCaseStudies />
      <EnergyConnect />
      <Footer/>
    </>
  );
};

export default Energy;
