import React from "react";
import "./BankServices.css";

const BankServices = () => {
  return (
 

    <div className="container" id="Services">
      <div className="row">
        <div className="col-lg-12 d-flex">
          <div className="col-lg-4 BankServices_Content">
            <h3>NAVIGATING THE NEXT FOR BANKING AND FINANCIAL SERVICES</h3>
            <p>
         LDAPP has earned a reputation as the vendor of choice for enabling banking Innovation in the Asia Pacific.
</p>
            <p>
              We are proud to be the preferred partner for some of the leading
              banks across the world. Our technology experts are helping to
              redefine banking experiences, disrupt the Financial Services
              market and fuel the Fintech revolution.
            </p>
            <p>
              The global financial services industry is undergoing digital
              disruption. Banks and other financial institutions need to embrace
              this transformation or risk becoming irrelevant. Keeping up with
              technology and the market is indispensable for survival.
              Modernizing existing systems to strengthen your core along with
              developing new applications to deliver innovation is the only way
              for banks survive this disruption.
            </p>
            <p>
              With our deep experience , we understand the
              challenges facing our banking clients.
            </p>
          </div>
          <div className="col-lg-8">
          <div className="d-flex">
          <div className="mobile_img1 py-5 col-lg-6">
            <h4 className="txt_01">CORPORATE BANKING

            </h4>
            <p className="txt_02">
              Primary Focus areas:
              <br />
              Accounts <span className="Line_color">| </span> Trade Finance{" "}
              Accounts <span className="Line_color">| </span> Collateral Management {" "}
              Accounts <span className="Line_color">| </span>Commercial Loans {" "}
              <span className="Line_color">| </span> Payments & Cash{" "}
              <span className="Line_color">| </span> Channels{" "}
              </p>
          </div>
          <div className="mobile_img2 py-5 col-lg-6">
            <h3 className="txt_01">GLOBAL MARKETS
            </h3>
            <p className="txt_02">
              Primary Focus areas:
              <br />
              Accounts <span className="Line_color">| </span> Fixed Income{" "}
              <span className="Line_color">| </span> Money Markets{" "}
              <span className="Line_color">| </span> Equities{" "}
              <span className="Line_color">| </span> Derivatives.{" "}
              <span className="Line_color">| </span> Commodities
              <span className="Line_color">| </span> Risk & Compliance

            </p>
          </div>
          </div>
          <div className=" mobile_img3 py-5 col-lg-12">
            <h3 className="txt_01">PRIVATE BANKING & WEALTH MANAGEMENT</h3>

            <p className="txt_02">
              Primary Focus areas:
              <br />
              Accounts <span className="Line_color">| </span>Core Banking {" "}
              <span className="Line_color">| </span> Simulation & Modeling{" "}
              <span className="Line_color">| </span>  Portfolio management{" "}
              <span className="Line_color">| </span> CRM{" "}
              <span className="Line_color">| </span> Order Management
              <span className="Line_color">| </span> Internet and Digital

            </p>
          </div>
          <div className="d-flex">
          <div className="mobile_img4 py-5 col-lg-6">
            <h3 className="txt_01">SHARED SERVICES
            </h3>
            <p className="txt_02">
              Primary Focus areas:
              <br />
              Accounts <span className="Line_color">| </span>Technology Infrastructure Services {" "}
              <span className="Line_color">| </span>Operations Support {" "}
              <span className="Line_color">| </span> Corporate Systems{" "}
              <span className="Line_color">| </span> Information Security {" "}
              <span className="Line_color">| </span> AML
              <span className="Line_color">| </span>  KYC, CDD
              <span className="Line_color">| </span> Reg Reporting

            </p>
          </div>
          <div className="mobile_img5 py-5 col-lg-6">
            <h3 className="txt_01">RETAIL BANKING
            </h3>
            <p className="txt_02">
              Primary Focus areas:
              <br />
              Accounts <span className="Line_color">| </span> Mortgages{" "}
              <span className="Line_color">| </span> Deposits and Loans{" "}
              <span className="Line_color">| </span> Teller{" "}
              <span className="Line_color">| </span> Cards{" "}
              <span className="Line_color">| </span> Internet and Digital
            </p>
          </div>
          </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className=" BankServices_Content">
               <p>Whether its enabling IoT for banking services, building the next generation of banking applications or streamlining and modernization of core banking operations, we have the experience, the people, the processes and the methodologies to help you get there.</p>
          </div>


        </div>
      </div>
    </div>
  );
};

export default BankServices;
