import React from "react";
import "./DigitalCustomerCaseStudies.css";
import img1 from "../../DigitalCustomer/DigitalCustomerCaseStudies/Assets/CxCase1.jpg";
import img2 from "../DigitalCustomerCaseStudies/Assets/CxCase2.jpg";

const DigitalCustomerCaseStudies = () => {
  return (
    <div className="container-fluid container_CaseStudies" id="CaseStudies">
      <div className="row">
        <div className="col-lg-12 Case_Studies ">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        <div className="col-lg-12 d-flex CaseStudies_Overall">
          <div className="Case_all">
            <img
              src={img1}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>
                PAYMENT INTEGRATION SERVICES FOR A GLOBAL PAYMENT SERVICE
                PROVIDER
              </h5>
              <p>
              It establishes the debit network and drive the adoption of electronic payments in  with the support of major banks functioning .


              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img2}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>
                MOBILE APPLICATION DEVELOPMENT FOR A REGIONAL BANK 
              </h5>
              <p>
              The digital banking program in delivering multiple cells which
comprises of mobile App and internet banking solution. The scope of service included design, build, test
and implementation services in multi squad and multi-phase approach..
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalCustomerCaseStudies;
