import React from "react";
import "./PublicSectorService.css";

const PublicSectorService = () => {
  return (
    <div className="container" id="Services">
      <div className="row">
        <div className="col-lg-12 d-flex">
          <div className="col-lg-4 BankServices_Content">
            <h3>DIGITAL SERVICES FOR PUBLIC SECTOR</h3>
            <p>
              LDPAA is partnering various Government and Public-Sector
              entities to co-create next-generation digital government services.
            </p>
            <p>
              We provide services, solutions and expertise to help Government
              and Public-Sector re-design online services and underlying
              processes to make them simpler to use and easier to access.
            </p>
            <p>
              We are supporting initiatives that make transacting with the
              Government easier and more seamless, streamline workflows and
              enhance process accuracy, increase convenience, improve service
              standards, and reduce processing time.
            </p>
            <p>
              As Governments and Public Sector move towards the next leap of
              digital engagement, beyond apps to conversational computing and
              voice-enabled services, Optimum is ready to help with its deep
              cross-platform experience.
            </p>
          </div>
          <div className="col-lg-8">
            <div className="d-flex">
              <div className="mobile_img1 py-5 col-lg-6">
                <h3 className="txt_01">DATA & ANALYTICS</h3>
                <p className="txt_02">
                  Proven and reusable data management and decision analytics to
                  gain deeper insights
                  <br />
                </p>
              </div>
              <div className="mobile_img2 py-5 col-lg-6">
                <h3 className="txt_01">CAPACITY SUPPORT FOR NICHE SKILLS</h3>
                <p className="txt_02">
                  Expertise on Demand: Staff Augmentation for the Public Sector
                  <br />
                </p>
              </div>
            </div>
            <div className=" mobile_img3 py-5 col-lg-12">
              <h3 className="txt_01">APPLICATION DEVELOPMENT MANAGEMENT</h3>

              <p className="txt_02">
                Leading the Way in Government Digital Transformation:
                Streamlining Operations and Enhancing Services
                <br />
              </p>
            </div>
            <div className="d-flex">
              <div className="mobile_img4 py-5 col-lg-6">
                <h3 className="txt_01">END TO END TESTING</h3>
                <p className="txt_02">
                  Improving the overall quality and reliability of the systems
                  and processes
                  <br />
                </p>
              </div>
              <div className="mobile_img5 py-5 col-lg-6">
                <h3 className="txt_01">DIGITAL TOOL & SOFTWARE SUPPORT</h3>
                <p className="txt_02">
                  Empowering Public Sector Governance through Digital Product
                  Licensing & Managed Services
                  <br />
                  {/* Accounts <span className="Line_color">| </span> Mortgages{" "}
                  <span className="Line_color">| </span> Deposits and Loans{" "}
                  <span className="Line_color">| </span> Teller{" "}
                  <span className="Line_color">| </span> Cards{" "}
                  <span className="Line_color">| </span> Internet and Digital */}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className=" BankServices_Content">
            <p>
              LDAPP’s Fintech expertise is helping Governments create a
              FinTech environment that supports innovation in the financial
              services, and encourages financial institutions such as banks, to
              experiment with the application of new technologies in a safe
              environment
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicSectorService;
