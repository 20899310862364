import React from "react";
import "./CloudCapabilities.css";
import img1 from "./Assets/img1.jpg";
import img2 from "./Assets/img2.jpg";
import img3 from "./Assets/img3.jpg";
import img4 from "./Assets/img4.jpg";

const CloudCapabilities = () => {
  return (
    <div className="container-fluid " id="Capabilities">
      <div className="row">
        <div className="col-lg-12">
          <div>
            <h1>APPLICATION SERVICES CAPABILITIES</h1>
          </div>
        </div>
        <div className="col-lg-12 Service_Content_01 my-5 ">
          <div className="col-lg-7 mx-3">
            <div className="Services_content01">
              <h3>CLOUD DEVELOPMENT SERVICES</h3>
              <p>
                We offer cloud application development services to assist you in
                developing robust cloud technologies, either by developing
                brand-new applications or by moving them from legacy systems.
              </p>
              <hr />
              <ul className="icon_li">
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i>Our services include
                  consulting, development, migration, integration or testing.
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-laughing"></i>We can help you
                  determine a choice of private,public or hybrid cloud platform
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 Image_service01">
            <img src={img1} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="col-lg-12 Service_Content_02 ">
          <div className="col-lg-5 Image_service02">
            <img src={img2} alt="" />
          </div>
          <div className="col-lg-7  ">
            <div className="Services_content02 ">
              <h3>CLOUD MIGRATION SERVICES</h3>
              <p>
                Cloud migration is a key step in achieving business growth,
                efficiency, agility and scalability. We have a long track record
                of successful infrastructure migrations across diverse industry
                sectors.
              </p>
              <hr />
              <ul className="icon_li">
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i> Migrating your
                  infrastructure to the cloud goes beyond just mapping over
                  identical services.
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-laughing"></i> At LDAPP SOLUTION, we audit
                  and evaluate your existing infrastructure and propose
                  migration recommendations that deliver your business needs.
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i>Our migration experts
                  will help you maximize the benefits of cloud, including high
                  scalability, availability and automation while minimizing
                  disruption and downtime during the transition.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-12 Service_Content_01 my-5">
          <div className="col-lg-7 mx-3">
            <div className="Services_content01">
              <h3>CLOUD ANALYTICS</h3>
              <p>
                The cloud has quickly become the destination for efficient data
                engineering. LDAPP SOLUTIONS Consulting can help you reap the benefits
                of the cloud with rapid spin up, improved flexibility, and high
                scalability.
                <br />
                Take care of your entire cloud engineering process. from
                strategy to development. designing and delivering seamless,
                safe, and secure analytics with help from the Optimum Consulting
                team
              </p>
              <hr />
              <ul className="icon_li">
                <p>Our cloud analytics services include:</p>
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i>
                  Cloud Data Pipelines
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-laughing"></i>Cloud Data Lakes and
                  Data Warehouses
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i>Dashboard and Report
                  Migrations
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i>OnPrem to Cloud
                  Migrations
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 Image_service01">
            <img src={img3} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="col-lg-12 Service_Content_02 ">
          <div className="col-lg-5 Image_service02">
            <img src={img4} alt="" />
          </div>
          <div className="col-lg-7  ">
            <div className="Services_content02 ">
              <h3>RISK, COMPLIANCE AND INFORMATION SECURITY</h3>
              <p>
                Our team of Banking sector experts can assist in building
                frameworks to ensure adherence to latest regulatory and security
                norms. Our experience of having assisted scores of clients in
                Banking industry gives us a unique perspective on Risk
                Management and Information Security Solutions across different
                parts of the banking enterprise.
                <br />
              </p>
              <hr />
              <ul className="icon_li">
                <p>
                  We cover all facets of enterprise Information security,
                  including:
                </p>
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i> Risk Framework,
                  Identification, Assessment, and Mitigation
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-laughing"></i>Security Incidents
                  Management and Reporting
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i> Regulatory and Audit
                  Compliance Management
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i>Risk Training and
                  Awareness
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudCapabilities;
