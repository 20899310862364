import React from "react";
import "./TestingCaseStudies.css";
import img1 from "../../TestingServices/TestingCaseStudies/Assets/TestingCase1.jpg";
import img2 from "../../Webapplication/CaseStudies/Assets/2.jpeg";
import img3 from "../../TestingServices/TestingCaseStudies/Assets/TestingCase3.webp";

const TestingCaseStudies = () => {
  return (
    <div className="container-fluid container_CaseStudies" id="CaseStudies">
      <div className="row">
        <div className="col-lg-12 Case_Studies ">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        <div className="col-lg-12 d-flex CaseStudies_Overall">
          <div className="Case_all">
            <img
              src={img1}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>
                MOBILE APPLICATION DEVELOPMENT  BANK 
              </h5>
              <p>
              Test advisory and managed testing of their new banking systems and integration testing for the complex touchpoints for multiple Fiserv products              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img2}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>
                NEXT GEN DIGITAL APPLICATION DEVELOPMENT AND TESTING FOR A
                MIDDLE EAST BANK
              </h5>
              <p>
              Functional, usability and performance testing of new digital wallet services for quick financial transactions, and performed end-to-end testing of mobile app and APIs
              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img3}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>
                PAYMENT INTEGRATION SERVICES FOR A GLOBAL PAYMENT SERVICE
                PROVIDER
              </h5>
              <p>
              Device testing for their mobile application based on iOS and android platforms over different physical devices. Determined the transparent data transmission in the devices connected via HFP .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestingCaseStudies;
