import React from "react";
import Header from "../../Components/Header/Header";
import DigitalCustomerOverview from "../../Components/Services/DigitalCustomer/DigitalCustomerOverview/DigitalCustomerOverview";
import DigitalCustomerCapabilities from "../../Components/Services/DigitalCustomer/DigitalCustomerCapabilities/DigitalCustomerCapabilities";
import DigitalCustomerTools from "../../Components/Services/DigitalCustomer/DigitalCustomerTools/DigitalCustomerTools";
import DigitalCustomerCaseStudies from "../../Components/Services/DigitalCustomer/DigitalCustomerCaseStudies/DigitalCustomerCaseStudies";
import DigitalCustomerBlogs from "../../Components/Services/DigitalCustomer/DigitalCustomerBlogs/DigitalCustomerBlogs";

const Digital = () => {
  return (
    <>
      <Header />
      <DigitalCustomerOverview />
      <DigitalCustomerCapabilities />
      <DigitalCustomerTools />
      <DigitalCustomerCaseStudies />
      <DigitalCustomerBlogs />
    </>
  );
};

export default Digital;
