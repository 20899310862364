import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./Talktous.css";
import img1 from "../../Components/Talktous/Assests/11.png";

const Talktous = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_hw35f1p', 'template_bk8nml5', form.current, 'WhUU1xEYCFZ0y2mSi')
      .then((result) => {
        console.log(result.text);
        e.target.reset();
        alert('Email sent...');
      }, (error) => {
        console.log(error.text);
        alert("Can't send...");
      });
  };

  return (
    <div className="container container_FastTalk">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-12">
          <div className="all">
            <div className="col-lg-6">
              <div className="content_1 p-4">
                <h2>Talk to Us</h2>
                <p className="text-muted py-3">
                  Got a question? Don’t hesitate to give us a call today or
                  shoot us an email.
                </p>
                <div className="contact-info">
                  <div>
                    <i className="bi bi-telephone-inbound-fill"></i> +91 7639158122
                  </div>
                  <div>
                    <i className="bi bi-envelope-arrow-down-fill"></i>Hello@ldappsolutions.com
                  </div>
                  <div className="content1_image">
                    <img src={img1} alt="Contact Us" />
                  </div>
                </div>
              </div>
            </div>
            <form className="contactForm" ref={form} onSubmit={sendEmail}>
              <h2>Send Message</h2>
              <div className="input-box">
                <input type="text" className="name" placeholder="Your Name" name="your_name" required />
              </div>
              <div className="input-box">
                <input type="email" className="email" placeholder="Your Email" name="your_email" required />
              </div>
              <div className="input-box">
                <textarea className="msg" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div className="input-box">
                <button type="submit" className="butn px-4 py-2">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Talktous;
