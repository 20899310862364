import React from "react";
import "./AIOverview.css";

const AIOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="Content_Development3">
            <h1>ARTIFICAL  INTELIGIENCE APPLICATION DEVELOPMENT</h1>
           <p>
            Empowering Your Business with Data Science and AI Technology
            </p>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-lg-12 my-3">
          <div className="Button_Contain">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Capabilities")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Capabilities
            </button>
            <button
             onClick={() => {
              document
                .getElementById("Tools")
                .scrollIntoView({ behavior: "smooth" });
            }}>Tools</button>
            <button
             onClick={() => {
              document
                .getElementById("CaseStudies")
                .scrollIntoView({ behavior: "smooth" });
            }}>Case Studies</button>
            <button
             onClick={() => {
              document
                .getElementById("Blogs")
                .scrollIntoView({ behavior: "smooth" });
            }}>Blogs</button>
          </div>
        </div>
      </div>
      <div className="text-info">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-lg-12 my-5 Overview_content">
          <p>
          Artificial Intelligence is the computer technology that simulates human intelligence using smart electronics and high-power processing units. Just as any ground-breaking innovation has ever done, AI has been disrupting the digital landscape in enormous scales. With its human-like self-analytic skills and logical reflexes, AI has established itself as the flag-bearer of the next-generation industrial revolution.
          </p>
          <p>
          The role of AI in web development cannot go unnoticed. Today, web app development has grown to an extent that it has given birth to whole new industries related to the field. Website developers compete in offering customers highly personalized and enhanced experiences which are achieved by using AI in web development.
 </p>
          
        </div>
      </div>
    </div>
  );
};

export default AIOverview;
