import React from 'react'
import "./Tools.css"
import img1 from "../../Webapplication/Tools/Assets/angular-js.png";
import img2 from "../../Webapplication/Tools/Assets/msoft.png";
import img3 from "../../Webapplication/Tools/Assets/net.png";
import img4 from "../../Webapplication/Tools/Assets/react.png";
import img5 from "../../Webapplication/Tools/Assets/spring-boot.png";
import img6 from "../../Webapplication/Tools/Assets/sql.png";
import Marquee from "react-fast-marquee";



const Tools = () => {
  return (
    <div className='container' id="Tools">
      <div className='row'>
        <div className='col-lg-12 Tech_sup '>
            <h1>TECHNOLOGY</h1>
            <p>TOOLS & TECHNOLOGY</p>
        </div>
        <div className='col-lg-12 my-4'>
        <Marquee>
           <div className='img_content_tools'>
            <img src={img1} alt="" />
            <img src={img2} alt="" />
            <img src={img3} alt="" />
            <img src={img4} alt="" />
            <img src={img5} alt="" />
            <img src={img6} alt="" />
           </div>
           </Marquee>
        </div>
      </div>
     
    </div>
  )
}

export default Tools
