import React from 'react';
import './BankCaseStudies.css';
import img1 from "../../Banking/BankCaseStudies/Assets/1 (1).jpeg";
import img2 from "../../Banking/BankCaseStudies/Assets/2.jpeg";
import img3 from "../../Banking/BankCaseStudies/Assets/3.jpeg";

const BankCaseStudies = () => {
  return (
    <div className='container-fluid container_CaseStudies'id="Case Studies">
      <div className='row'>
        <div className='col-lg-12 Case_Studies '  >
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        <div className='col-lg-12 d-flex CaseStudies_Overall'>
          <div className="Case_all">
            <img
              src={img1}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1"><i className="bi bi-file-pdf-fill"></i>E BOOK</div>
              <h5>
              FM DASHBOARD FOR REGULATORY COMPLIANCE

              </h5>
              <p>
              LDAPP SOLUTIONS played a vital role in planning & building next generation platform for both web and mobile app to automate end to end FM Supervisory functions for ensuring Traders Regulatory Complianc</p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img2}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1"><i className="bi bi-file-pdf-fill"></i>E BOOK</div>
              <h5>
              TRADE FINANCE TRANSFORMATION

              </h5>
              <p>
              Legacy platforms with single application for deployment created huge resistance to new developments in case of organizational changes and/or business reengineering


              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img3}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1"><i className="bi bi-file-pdf-fill"></i>E BOOK</div>
              <h5>
              NEXT GEN DIGITAL APPLICATION DEVELOPMENT AND TESTING FOR A MIDDLE EAST BANK

              </h5>
              <p>
              It is a leading financial institution with an expanding footprint across the Middle East with a strong presence in the financial capitals of the world .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BankCaseStudies;
