import React from 'react'

const FAQs = () => {
  return (
    <div className='container'>
        <div className='row'>
            <div className='col-lg-12'>
                <h1>FAQs</h1>
            </div>
            <div className='col-lg-12'>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                   What is a Data Lab Service?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                  In simpler terms, a Data Lab is a system created to explore the potential value of your data thoroughly. The best thing about these labs is that they can be customized to suit the needs of any project or experiment, which is why these labs are such a vital part of the data processes.                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                 What is the Purpose of Data Labs?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                  The Purpose of a data lab is to enhance the organization’s overall operations. It allows a business to test, develop, and deploy new technologies and reduces operational risks by allowing it to play with data in a controlled environment before deploying it across the entire system.

To put it another way, organizations that incorporate a data lab strategy are more likely to remain up-to-date and competitive in an ever-evolving technological landscape.                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
What Are the Benefits of Using Data Lab as a Service?                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                  Opting for this service allows for data analysis capabilities accelerated decision-making processes lowered risks through experimentation and enhanced collaboration, among professionals working with data.                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                   Will Data Lab Solve All Business Data Problems?

                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                  Experience increased computer efficiency at scale and achieved high data integration capabilities, making discovering clinical data correlations easier than ever with multi-modal and multi-omics.                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                   Are Data Lab Services Tailored to Specific Industries?

                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                  Absolutely! Data Lab services can be tailored according to specific industries equipping them with industry data solutions and analytical capabilities.                  </div>
                </div>
              </div>
            </div>

            </div>

        </div>
      
    </div>
  )
}

export default FAQs
