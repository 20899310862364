import React from "react";
import "./Banner.css";
import img1 from "../../Components/Banner/Assets/2.jpg";
import img2 from "../../Components/Banner/Assets/3.jpg";
import img3 from "../../Components/Banner/Assets/4.jpg";

const Banner = () => {
  return (
    <div
      id="carouselExampleAutoplaying"
      className="carousel slide overall_01"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner overall_02">
        <div className="carousel-item active back_color1">
          <div className="col-lg-12 d-flex justify-content-center align-items-center">
            <div className="col-lg-6 ">
              <h1 className="title">
                Accelerate Life Science business growth with AI-powered
                Analytics
              </h1>
              <p className="paragraph">
                On the journey from 'Molecule to Medicine' we fast-track
                innovation, unlocking the transformative power of data with
                Generative AI. This speeds growth, revolutionizes Pharmaceutical
                Organizations, optimizing Drug Discovery to Drug
                Commercialization.
              </p>

              <div>
                <button type="button" className="btn2">
                  Learn More<i class="bi bi-arrow-right"></i>
                </button>
              </div>
            </div>
            <div className="col-lg-6 image">
              <img
                src={img1}
                alt=""
                style={{ width: "auto", height: "500px" }}
              />
            </div>
          </div>
        </div>
        <div className="carousel-item back_color2">
          <div className="col-lg-12 d-flex justify-content-center align-items-center">
            <div className="col-lg-6 ">
              <h1 className="title">
                Accelerate Life Science business growth with AI-powered
                Analytics
              </h1>
              <p className="paragraph">
                On the journey from 'Molecule to Medicine' we fast-track
                innovation, unlocking the transformative power of data with
                Generative AI. This speeds growth, revolutionizes Pharmaceutical
                Organizations, optimizing Drug Discovery to Drug
                Commercialization.
              </p>

              <div>
                <button type="button" className="btn2">
                  Learn More<i class="bi bi-arrow-right"></i>
                </button>
              </div>
            </div>
            <div className="col-lg-6 image">
              <img
                src={img2}
                alt=""
                style={{ width: "auto", height: "500px" }}
              />
            </div>
          </div>
        </div>
        <div className="carousel-item back_color3">
          <div className="col-lg-12 d-flex justify-content-center align-items-center">
            <div className="col-lg-6 ">
              <h1 className="title">
                Accelerate Life Science business growth with AI-powered
                Analytics
              </h1>
              <p className="paragraph">
                On the journey from 'Molecule to Medicine' we fast-track
                innovation, unlocking the transformative power of data with
                Generative AI. This speeds growth, revolutionizes Pharmaceutical
                Organizations, optimizing Drug Discovery to Drug
                Commercialization.
              </p>

              <div>
                <button type="button" className="btn2">
                  Learn More<i class="bi bi-arrow-right"></i>
                </button>
              </div>
            </div>
            <div className="col-lg-6 image">
              <img
                src={img3}
                alt=""
                style={{ width: "auto", height: "500px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
