import React from "react";
import GlobalOverview from "../../Components/Industries/GlobalCapability/GlobalOverview/GlobalOverview";
import Header from "../../Components/Header/Header";
import GlobalService from "../../Components/Industries/GlobalCapability/GlobalService/GlobalService";
import GlobalStudies from "../../Components/Industries/GlobalCapability/GlobalCaseStudies/GlobalStudies";
import GlobalConnect from "../../Components/Industries/GlobalCapability/GlobalConnect/GlobalConnect";
import Footer from "../../Components/Footer/Footer";

const Global = () => {
  return (
    <>
      <Header />
      <GlobalOverview />
      <GlobalService />
      <GlobalStudies />
      <GlobalConnect />
      <Footer/>
    </>
  );
};

export default Global;
