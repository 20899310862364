import React, { useState, useEffect } from "react";
import "./Customers.css";
import image1 from "../../Components/Customers/Assets/1.jpeg";
import image2 from "../../Components/Customers/Assets/2.jpeg";
import image3 from "../../Components/Customers/Assets/3.jpeg";
import image4 from "../../Components/Customers/Assets/4.jpeg";
import image5 from "../../Components/Customers/Assets/5.jpeg";
import image6 from "../../Components/Customers/Assets/6.jpeg";
import image7 from "../../Components/Customers/Assets/7.jpeg";
import image8 from "../../Components/Customers/Assets/8.jpeg";
import image9 from "../../Components/Customers/Assets/9.jpeg";
import image10 from "../../Components/Customers/Assets/10.jpeg";
import image11 from "../../Components/Customers/Assets/11.jpeg";
import image12 from "../../Components/Customers/Assets/12.jpeg";
import AOS from 'aos';
import 'aos/dist/aos.css';


const images1 = [image1, image2];
const images2 = [image3, image4];
const images3 = [image5, image6];
const images4 = [image7, image8];
const images5 = [image9, image10];
const images6 = [image11, image12];


const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images1.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container">
      <div className="row py-3">
        <div className="row py-3">
        <div className="col-lg-12 py-3">
           <h1>LDAPP's Happy Customers</h1>
        </div>
        </div>
       
       <div className="row py-3">
        <div className="overall">
        <div className="col-lg-2">
          <div className="image-slider">
            {images1.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`slider-image-${index}`}
                className={`slider-image ${index === currentIndex ? "active" : ""}`}
              />
            ))}
          </div>
        </div>
        <div className="col-lg-2">
          <div className="image-slider">
            {images2.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`slider-image-${index}`}
                className={`slider-image ${index === currentIndex ? "active" : ""}`}
              />
            ))}
          </div>
        </div>
        <div className="col-lg-2">
          <div className="image-slider">
            {images3.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`slider-image-${index}`}
                className={`slider-image ${index === currentIndex ? "active" : ""}`}
              />
            ))}
          </div>
        </div>
         <div className="col-lg-2">
          <div className="image-slider">
            {images4.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`slider-image-${index}`}
                className={`slider-image ${index === currentIndex ? "active" : ""}`}
              />
            ))}
          </div>
        </div>
         <div className="col-lg-2">
          <div className="image-slider">
            {images5.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`slider-image-${index}`}
                className={`slider-image ${index === currentIndex ? "active" : ""}`}
              />
            ))}
          </div>
        </div>
        <div className="col-lg-2">
          <div className="image-slider">
            {images6.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`slider-image-${index}`}
                className={`slider-image ${index === currentIndex ? "active" : ""}`}
              />
            ))}
          </div>
        </div>
        </div>
      
       </div>
        
      </div>
    </div>
  );
};

export default ImageSlider;
