import React, { useState } from 'react';

const CandidateProfile = () => {
    const [selectedPosition, setSelectedPosition] = useState('');
    const [showInputField, setShowInputField] = useState(false);
    const [skills, setSkills] = useState([]);
    const [showSkillsDropdown, setShowSkillsDropdown] = useState(false);
    const [resumeFile, setResumeFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSelectPosition = (position) => {
        setSelectedPosition(position);
        setShowInputField(true);
    };

    const skillOptions = [
        'Front End Developer',
        'Back End Developer',
        'Java Full Stack Developer',
        'MERN Stack Developer',
        'Flutter Developer',
        'Python Developer',
        'Data Science Engineer',
        'Machine Learning Engineer',
        'QA Mobile Tester',
        'QA Automation Tester'
    ];

    const handleSelectSkill = (skill) => {
        if (!skills.includes(skill)) {
            setSkills([...skills, skill]);
        }
        setShowSkillsDropdown(false);
    };

    const handleRemoveSkill = (skill) => {
        setSkills(skills.filter((s) => s !== skill));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const allowedFormats = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

        if (file && allowedFormats.includes(file.type)) {
            setResumeFile(file);
            setErrorMessage('');
        } else {
            setErrorMessage('Only PDF and Word documents are accepted.');
            setResumeFile(null);
        }
    };

    const handleRemoveFile = () => {
        setResumeFile(null);
        setErrorMessage('');
    };

    return (
        <div className='container my-5 py-5'>
            <div className='row'>
                <div className='col-lg-12'>
                    <h1>Candidate Profile Submission Form</h1>
                    <p>Applying should be a breeze, not a maze! We've revamped our application process with a single-page form and one-click submission. Say goodbye to endless forms and hello to a smooth process that shows we value your time.</p>
                    <form>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Full Name </label>
                            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Candidate Email ID</label>
                            <input type="email" className="form-control" id="exampleInputPassword1" />
                            <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Phone</label>
                            <input type="tel" className="form-control" id="exampleInputPassword1" />
                            <div id="emailHelp" className="form-text">Please provide a contact number that we can reach out to you.</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Applied Position</label>
                            <div className="dropdown">
                                <a
                                    className="btn dropdown-toggle"
                                    href="#"
                                    role="button"
                                    id="companyDropdown"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {selectedPosition || 'Applied'}
                                </a>
                                <div className="dropdown-menu" aria-labelledby="companyDropdown">
                                    {skillOptions.map((skill, index) => (
                                        <a key={index} className="dropdown-item" href="#" onClick={() => handleSelectPosition(skill)}>
                                            {skill}
                                        </a>
                                    ))}
                                </div>
                            </div>
                            {showInputField && (
                                <div className="mt-3">
                                    <label htmlFor="additionalInfo" className="form-label">Additional Information</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="additionalInfo"
                                        placeholder={`Additional info about ${selectedPosition}`}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Select Skills</label>
                            <div className="dropdown mb-3">
                                <button
                                    className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="skillsDropdown"
                                    onClick={() => setShowSkillsDropdown(!showSkillsDropdown)}
                                    aria-expanded={showSkillsDropdown}
                                >
                                    Select a skill
                                </button>
                                {showSkillsDropdown && (
                                    <ul className="dropdown-menu show" aria-labelledby="skillsDropdown">
                                        {skillOptions.map((skill, index) => (
                                            <li key={index}>
                                                <button className="dropdown-item" onClick={() => handleSelectSkill(skill)}>
                                                    {skill}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            <div>
                                <h5>Selected Skills:</h5>
                                <ul>
                                    {skills.map((skill, index) => (
                                        <li key={index}>
                                            {skill} 
                                            <button className="btn btn-danger btn-sm ms-2" onClick={() => handleRemoveSkill(skill)}>
                                                Remove
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="coverLetter" className="form-label">Cover Letter (Limit to 200 words)</label>
                            <textarea className="form-control" id="coverLetter"></textarea>
                            <div id="emailHelp" className="form-text">While we would look at your resume for sure, but it would help us if you can provide us brief insights like - what industry/roles/companies/salary/city/skill-sets you are targeting for your next stint and why would you be a great fit.</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="resume" className="form-label">Resume</label>
                            <input type="file" className="form-control" id="resume" onChange={handleFileChange} />
                            {resumeFile && (
                                <div className="mt-3">
                                    <p>Selected File: {resumeFile.name}</p>
                                    <button className="btn btn-danger btn-sm" onClick={handleRemoveFile}>Remove</button>
                                </div>
                            )}
                            {errorMessage && (
                                <div className="mt-3 text-danger">
                                    {errorMessage}
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="sourcerEmail" className="form-label">Email ID of Sourcer</label>
                            <input type="email" className="form-control" id="sourcerEmail" />
                            <div id="emailHelp" className="form-text">If you are submitting on behalf of the candidate, please provide your email-id to get some karma points.</div>
                        </div>
                        <div className="mb-3 form-check">
                            <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                            <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CandidateProfile;
