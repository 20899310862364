import React from "react";
import "./DigitalCustomerOverview.css";

const DigitalCustomerOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="Content_Development5">
            <h1>DIGITAL CUSTOMER EXPERIENCE</h1>

            <p>
              Providing seamless, personalized, and positive experience for
              customers <br />
              in order to increase loyalty, satisfaction, and advocacy.
            </p>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-lg-12 my-3">
          <div className="Button_Contain">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Capabilities")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Capabilities
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Tools")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Tools
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("CaseStudies")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Case Studies
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Blogs")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Blogs
            </button>
          </div>
        </div>
      </div>
      <div className="text-info">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-lg-12 my-5 Overview_content">
          <p>
            Digital customer experience (DCX) refers to the interactions that
            customers have with a company through digital channels, such as a
            company’s website, mobile app, social media, or online customer
            service.
          </p>
          <p>
            It encompasses all aspects of a customer’s journey, including
            discovery, research, purchase, and post-purchase support, and it can
            include both self-service and assisted-service interactions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DigitalCustomerOverview;
