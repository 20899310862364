import React from "react";
import "./EnergyOverview.css";

const EnergyOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="Content_Development14">
            <h1>Energy & Utilizes </h1>
            <h1>Software Development Company</h1>
            <p>
              With our end-to-end application development, maintenance, and
              consulting <br />
              solutions for businesses operating in the energy and utility
              industry
            </p>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-lg-12 my-3">
          <div className="Button_Contain">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Services")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Services
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Case Studies")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Case Studies
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Connect")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Connect
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Blogs")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Blogs
            </button>
          </div>
        </div>
      </div>
      <div className="text-info">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-lg-12 my-5 Overview_content">
          <p>
            LDAPP Solutions is a leading Utility that assists companies, energy
            hardware producers, and energy software developers in obtaining
            adaptable Custom Energy and utility software solutions that control
            energy deliveries. We support comprehensive software and app
            solutions to track the efficiency of power transmission equipment
            and ensure efficient utility use in homes and contemporary
            businesses.
          </p>
          <p>
            With a mission to provide technological advancements to enterprises
            via innovative solutions, our learned team of professionals works
            hand in hand with businesses, understanding their needs and
            delivering research-driven personalized solutions. Our value
            proposition is to make no compromises regarding functionality,
            usability, service, or cost while providing analytical and
            actionable insights to transform their core environment and
            streamline energy workflows.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EnergyOverview;
