import React from "react";
import "./DevopsStudies.css";
import img1 from "../../Webapplication/CaseStudies/Assets/1 (1).jpeg";
import img2 from "../../DevopsAgile/DevopsStudies/Assets/DevopCase1.webp";

const DevopsStudies = () => {
  return (
    <div className="container-fluid container_CaseStudies" id="CaseStudies">
      <div className="row">
        <div className="col-lg-12 Case_Studies ">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        <div className="col-lg-12 d-flex CaseStudies_Overall">
          <div className="Case_all">
            <img
              src={img1}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>
                IMPLEMENTING A DEVSECOPS PLATFORM FOR COUNTRY’S CENTRAL BANK AND
                FINANCIAL REGULATORY AUTHORITY
              </h5>
              <p>
              Moreover, Azure Artifacts enable users to manage all package types like npm, Maven, etc., and keep them organized in a central library scoped only to the specific project.
              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img2}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>DEVOPS IMPLEMENTATION FOR A GLOBAL UK BANK</h5>
              <p>
              Cater to any DevOps need regardless of the programming language, technology, or the targeted platform.
              Deploy anywhere from containers to third-party clouds.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevopsStudies;
