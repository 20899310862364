import React from 'react';
import "./BankConnect.css";
import img1 from "../../Banking/BankConnect/Assets/1.jpg";

const BankConnect = () => {
  return (
    <div className='container' id='Connect'>
        <div className='row'>
            <div className='col-lg-12'>
                <div className='BankConnect_contentimg'>
                    <img src={img1} alt="" />
                    <div className='BankConnect_content'>
                        <h1>CONNECT WITH US FOR</h1>
                        <h1>BANKING & FINANCIAL SOLUTIONS</h1>
                        <div className='Button_ContactUs'>
                            <button>Contact Us</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default BankConnect;
