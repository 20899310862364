import React from "react";
import "./DevopsBlogs.css";
import img3 from "../../Webapplication/Blogs/Assets/1.jpg";
import img1 from "../../Webapplication/Blogs/Assets/2.jpg";
import img2 from "../../Webapplication/Blogs/Assets/3.jpg";

const DevopsBlogs = () => {
  return (
    <div className="container-fluid container_blog" id="Blogs">
      <div className="row">
        <div className="col-lg-12 Blog_Studies ">
          <h1>LEADING OUR WAY</h1>
          <p>BLOGS</p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className="Blog_1">
            <div className="zoom-img">
              <img src={img1} alt="" className="img-fluid" />
            </div>
            <h4>CLOUD MIGRATION – INFRA AND APPS</h4>
            <div className="Blog_1_sub">
              <span class="badge text-bg-warning">AMD</span>
              <p>FEBRUARY 13, 2023</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="Blog_1">
            <div className="zoom-img">
              <img src={img2} alt="" className="img-fluid" />
            </div>
            <h4>INDUSTRY TRENDS ON DEVOPS</h4>
            <div className="Blog_1_sub">
              <span class="badge text-bg-warning">AMD</span>
              <p>JANUARY 27, 2023</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="Blog_1">
            <div className="zoom-img">
              <img src={img3} alt="" className="img-fluid" />
            </div>
            <h4>DEVSECOPS – APPLICATION AND USES</h4>
            <div className="Blog_1_sub">
              <span class="badge text-bg-warning">FUTURE TECH</span>
              <p>MARCH 8, 2023</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevopsBlogs;
