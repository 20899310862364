import React from 'react'
import './Peopleall.css'
import img1 from "../../PeopleCareers/Peopleall/Assets/1.jpeg";
import img2 from "../../PeopleCareers/Peopleall/Assets/2.jpeg";
import img3 from "../../PeopleCareers/Peopleall/Assets/3.jpeg";
import img4 from "../../PeopleCareers/Peopleall/Assets/4.jpeg";
import img5 from "../../PeopleCareers/Peopleall/Assets/5.jpeg";

const Peopleall = () => {
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='people_title'>
                        <h1>Life at LDAPP</h1>
                        <p>At LDAPP, we have created a positive work environment that supports your excellence, stimulates your curiosity, and fuels your creativity. No matter where you are, you can be part of our cohesive workforce!</p>
                        
                         </div>
                </div>
                <div className='col-lg-12 py-4'>
                    <div className='row'>
                        <div className='col-lg-3 Box_1'>
                            <h1>Ownership</h1>
                        </div>
                        <div className='col-lg-6 Box_2'>
                            <img src={img1} alt="" />
                        </div>
                        <div className='col-lg-3 Box_3'>
                            <h1>Teamwork</h1>
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-lg-12 d-flex'>
                            <div className='col-lg-3 image_2'>
                                <img src={img3} alt="" />
                            </div>
                            <div className='col-lg-9 '>
                                <div className='d-flex'>
                                    <div className='col-lg-5 Box_4 '>
                                        <h1>Self-Reliance</h1>
                                    </div>
                                    <div className='col-lg-3 Box_5 '>
                                        <h1>Passion</h1>
                                    </div>
                                    <div className='col-lg-4 image_3 '>
                                        <img src={img2} alt="" />

                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div className='col-lg-4 Box_6'>
                                        <h1>Empathy</h1>
                                    </div>
                                    <div className=' col-lg-4 image_4'>
                                        <img src={img4} alt="" />
                                    </div>
                                    <div className='col-lg-4 Box_7'>
                                        <h1>Dedication</h1>
                                    </div>

                                </div>


                            </div>

                        </div>


                    </div>

                </div>

            </div>
        </div>
    )
}

export default Peopleall
