import React from 'react'
import "./People.css"
import vedio1 from "../../Components/PeopleCareers/Assets/2.mp4"

const People = () => {
  return (
  
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='player_wrapper01'>
              <video className="react_player01" src={vedio1} loop autoPlay muted>
              Your browser does not support HTML video.
            </video>
              </div>

            </div>

          </div>

        </div>
      
   
  )
}

export default People
