import React from 'react';
import './Vedio.css';
import ReactPlayer from 'react-player';

import vedio1 from "../Vedio/Assests/11.mp4"

const Vedio = () => {
  return (
    <div className='container'>
        <div className='row'>
        <div className='col-lg-12'>
    <div className='player-wrapper'>
      <ReactPlayer
        className='react-player'
        url={vedio1}
        width='100%'
        height='100%'
        playing={true}  // Autoplay the video
        loop={true}     // Loop the video
        muted={true}    // Mute the video
      />
      <div className='content'>
        <h1>Building Data Solutions for Life Sciences to drive Innovation</h1>
        <p>With cloud computing for Life Sciences by your side, the sky's the limit. Turn to Agilisium to unlock the  full <br/>potential of data-driven cloud solutions to reduce the running cost, <br/>improve engagement, and seamless collaboration.</p>
        <button type="button" className="btn101">
          Contact
        </button>
      </div>
    </div>
    </div>
    </div>
    <div className='row py-3 my-3'>
        <div className='col-lg-12'>
            <h1>Our Expertise</h1>
            <p>Agilisium stands out as a distinguished Life Sciences Data, AI, and Analytics company, specializing in tailoring advanced solutions to the unique demands of the life sciences industry. With a focus on precision medicine analytics, real-world evidence, and regulatory compliance, Agilisium offers comprehensive end-to-end data management services designed to handle complex datasets while leveraging artificial intelligence to accelerate drug discovery, optimize clinical trials, and enhance decision-making in research and development.</p>

        </div>

    </div>
    
    </div>
  );
}

export default Vedio;
