import React from "react";
import "./CloudOverview.css";

const CloudOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="Content_Development7">
            <h1>CLOUD AND SECURITY</h1>

            <p>Efficiently scalable, easily manageable, undeniably powerful</p>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-lg-12 my-3">
          <div className="Button_Contain">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Capabilities")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Capabilities
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Tools")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Tools
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("CaseStudies")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Case Studies
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Blogs")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Blogs
            </button>
          </div>
        </div>
      </div>
      <div className="text-info">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-lg-12 my-5 Overview_content">
          <p>
            LDAPP SOLUTIONS plays a pivotal role in organizations develop and implement
            mobile, digital, and cloud solutions in order to drive business
            transformation and improve efficiency. Mobile, digital, and cloud
            technologies have become increasingly important for businesses in
            recent years, as they enable companies to deliver innovative and
            personalized customer experiences, increase agility and reach, and
            reduce operational costs.
          </p>
          <p>
            By working with a team of experts who have experience driving
            digital and cloud transformation programs across a range of
            technologies, businesses can gain the full benefits of these
            technologies and improve the resilience of their IT landscape.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CloudOverview;
