import React from "react";
import "./TeleComCaseStudies.css";
import img1 from "../TeleComCaseStudies/Assets/Telecom1.jpg";

const TeleComCaseStudies = () => {
  return (
    <div className="container-fluid container_CaseStudies" id="Case Studies">
      <div className="row">
        <div className="col-lg-12 Case_Studies ">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        <div className="col-lg-12 d-flex CaseStudies_Overall">
          <div className="Case_all">
            <img
              src={img1}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>The Role of Telecoms in Facilitating Remote Work</h5>

              <p>
              Our team of networking experts have helped a diverse set of companies bring their organisations into the 21st century with enterprise-grade internet speeds and secure future-proof networks.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeleComCaseStudies;
