import React from "react";
import Header from "../../Components/Header/Header";
import TestingOverview from "../../Components/Services/TestingServices/TestingOverview/TestingOverview";
import TestingCapabilities from "../../Components/Services/TestingServices/TestingCapabilities/TestingCapabilities";
import TestingTools from "../../Components/Services/TestingServices/TestingTools/TestingTools";
import TestingCaseStudies from "../../Components/Services/TestingServices/TestingCaseStudies/TestingCaseStudies";
import TestingBlogs from "../../Components/Services/TestingServices/TestingBlogs/TestingBlogs";

const Testing = () => {
  return (
    <>
      <Header />
      <TestingOverview />
      <TestingCapabilities />
      <TestingTools />
      <TestingCaseStudies />
      <TestingBlogs />
    </>
  );
};

export default Testing;
