import React from "react";
import "./StandOut.css";
import CountUp from "react-countup";

const StandOut = () => {
  return (
    <div className="container py-3 my-3 Back">
      <div className="row">
        <div className="col-lg-12">
          <div>
            <h1>What Makes Us Stand Out</h1>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 col-12">
          <div className="text1222">
            <h1 className="text_design">
              <CountUp start={0} end={10} duration={2} />+
            </h1>
            <p className="card1">
              Proprietary Solutions can be easily customized and integrated to your enterprise ecosystem which helps you take the edge over the Time-to-Market.
            </p>
          </div>
        </div>

        <div className="col-lg-4 col-12">
          <div className="text5555">
            <h1 className="text_design">
              <CountUp start={0} end={20} duration={2} />+
            </h1>
            <p className="card1">
              Accelerators and Frameworks helps the Data and Application engineering team to fast-track their apps development through our customizable accelerators and frameworks.
            </p>
          </div>
        </div>

        <div className="col-lg-4 col-12">
          <div className="text66666">
            <h1 className="text_design">
              <CountUp start={0} end={50} duration={2} />+
            </h1>
            <p className="card1">
              Curated Business Case Studies reflects our strength in business problem solving capabilities in equation with developing Generative AIsolutions that fits the industry need.
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 col-12">
          <div className="text332 border-start-red">
            <h1 className="text_design">
              <CountUp start={0} end={100} duration={2} />+
            </h1>
            <p className="card1">
              Proprietary Solutions can be easily customized and integrated to your enterprise ecosystem which helps you take the edge over the Time-to-Market.
            </p>
          </div>
        </div>

        <div className="col-lg-4 col-12">
          <div className="text333">
            <h1 className="text_design">
              <CountUp start={0} end={75} duration={2} />+
            </h1>
            <p className="card1">
              Accelerators and Frameworks helps the Data and Application engineering team to fast-track their apps development through our customizable accelerators and frameworks.
            </p>
          </div>
        </div>

        <div className="col-lg-4 col-12">
          <div className="text334">
            <h1 className="text_design">
              <CountUp start={0} end={15} duration={2} />+
            </h1>
            <p className="card1">
              Curated Business Case Studies reflects our strength in business problem solving capabilities in equation with developing Generative AIsolutions that fits the industry need.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandOut;
