import React from "react";
import "./GlobalStudies.css";
import img1 from "../GlobalCaseStudies/Assets/global2.jpg";
import img2 from "../GlobalCaseStudies/Assets/Global3.jpg";
import img3 from "../GlobalCaseStudies/Assets/Global4.jpg";

const GlobalStudies = () => {
  return (
    <div className="container-fluid container_CaseStudies" id="Case Studies">
      <div className="row">
        <div className="col-lg-12 Case_Studies ">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        <div className="col-lg-12 d-flex CaseStudies_Overall">
          <div className="Case_all">
            <img
              src={img1}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>FM DASHBOARD FOR REGULATORY COMPLIANCE</h5>
              <p>
              LDAPP played a vital role in planning & building next generation platform for both web and mobile app to automate end to end FM Supervisory functions for ensuring Traders Regulatory Compliance


              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img2}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>TRADE FINANCE TRANSFORMATION</h5>
              <p>
              Delivered several functionalities such as Trade-AML, Name-screening, Sanctions, Trade Asset Management, Trade MIS & Analytics, Cross-sell & Up-sell Opportunities, TradeLimits/Facilities, Trade Mobile & Channels
              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img3}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>TRADE FINANCE TRANSFORMATION</h5>
              <p>
              Together with Optimum, bank executed trade finance transformation program developing 3 enterprise scale platforms to provide a consistent hassle free TF offerings
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalStudies;
