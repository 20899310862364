import React from "react";
import Header from "../../Components/Header/Header";
import BankOverview from "../../Components/Industries/Banking/BankOverview/BankOverview";
import BankCaseStudies from "../../Components/Industries/Banking/BankCaseStudies/BankCaseStudies";
import BankConnect from "../../Components/Industries/Banking/BankConnect/BankConnect";
import BankServices from "../../Components/Industries/Banking/BankService/BankServices";
import Footer from "../../Components/Footer/Footer";

const Banking = () => {
  return (
    <>
      <Header />
      <BankOverview />
      <BankServices />
      <BankCaseStudies />
      <BankConnect />
      <Footer/>
    </>
  );
};

export default Banking;
