import React from 'react'
import Header from "../Components/Header/Header"
import Customers from '../Components/Customers/Customers'
import Banner from '../Components/Banner/Banner'
import Companies from '../Components/Companies/Companies'
import Cards from '../Components/Cards/Cards'
import Recentwork from '../Components/Recentwork/Recentwork'
import Award from '../Components/Award/Award'
import Talktous from '../Components/Talktous/Talktous'
import Footer from '../Components/Footer/Footer'
import Advance from "../Components/Advanceddata/Advance";
import ClientTestimonals from "../Components/Testimonals/ClientTestimonals"
import VedioHome from '../Components/VedioHome/VedioHome'


const Home = () => {
  return (
    <>
    <br/>
    <Header/>
   <VedioHome/>

    <Banner/>
    <Customers/>
   
    <Companies/>
    <Cards/>
    <Recentwork/>
    {/* <Award/> */}
    {/* <ClientTestimonals/> */}
    
    {/* <Advance/> */}
    <Footer/>
    </>
  )
}

export default Home
