import React from 'react'
import Header from '../../Components/Header/Header'
import CandicateProfile from '../../Components/CandicateProfile/CandicateProfile'

const Candicateprofile = () => {
  return (
    <>
    <Header/>
    <CandicateProfile/>
    </>
  )
}

export default Candicateprofile