import React from "react";
import Header from "../../Components/Header/Header";
import ECommerceOverview from "../../Components/Industries/ECommerce/ECommerceOverview/ECommerceOverview";
import ECommerceService from "../../Components/Industries/ECommerce/ECommerceService/ECommerceService";
import ECommerceCaseStudies from "../../Components/Industries/ECommerce/ECommmerceCaseStudies/ECommerceCaseStudies";
import ECommerceConnect from "../../Components/Industries/ECommerce/ECommerceConnect/ECommerceConnect";
import Footer from "../../Components/Footer/Footer";

const ECommerce = () => {
  return (
    <>
      <Header />
      <ECommerceOverview />
      <ECommerceService />
      <ECommerceCaseStudies />
      <ECommerceConnect />
      <Footer/>
    </>
  );
};

export default ECommerce;
