import React from "react";
import "./BLOG.css";
import vedio11 from "../../LifeScience/BLOG/Assets/01.mp4";
import ReactPlayer from "react-player";

const BLOG = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 ">
            <div className="react_player">
          <ReactPlayer
            className="react-player "
            url={vedio11}
            width="100%"
            height="110vh"
            playing={true} // Autoplay the video
            loop={true} // Loop the video
            muted={true} // Mute the video
          />
            <div className="content_0001">
            <i class="bi bi-device-hdd-fill"></i>
            <h4>Big data analytics for the pharmaceutical industry- A big leap towards digital success</h4>
            <p>Today’s world is data-driven. From your phone, to your laptop, to your smart home devices, everyday data is being generated in the quintillions. This can be a mess, especially if you run a business.</p>
            <span>Read More <i class="bi bi-arrow-right"></i></span>
            </div>

        
          </div>
        </div>
      </div>
    </div>
  );
};

export default BLOG;
