import React from "react";
import "./Overview.css";

const Overview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="Content_Development">
            <h1> WEB   ENTERPRICES APPLICATION DEVELOPMENT </h1>
            <p>
               IT services innovation and building
              flexible, scalable, and reliable Applications
            </p>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-lg-12 my-3">
          <div className="Button_Contain">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Capabilities")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Capabilities
            </button>
            <button
             onClick={() => {
              document
                .getElementById("Tools")
                .scrollIntoView({ behavior: "smooth" });
            }}>Tools</button>
            <button
             onClick={() => {
              document
                .getElementById("CaseStudies")
                .scrollIntoView({ behavior: "smooth" });
            }}>Case Studies</button>
            <button
             onClick={() => {
              document
                .getElementById("Blogs")
                .scrollIntoView({ behavior: "smooth" });
            }}>Blogs</button>
          </div>
        </div>
      </div>
      <div className="text-info">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-lg-12 my-5 Overview_content">
          <p>
            LDAPP SOLUTIONS is an Applications Services partner for several global
            enterprises who lean on our expertise to build software application
            solutions, adaptable digital platforms, develop next generation
            capabilities and create that competitive edge to keep them ahead in
            the race.
          </p>
          <p>
            LDAPP’s exceptional strength in Application Services derives from
            on our unique PPIR© approach that combines people, processes and
            innovation to deliver exceptional results
          </p>
        </div>
      </div>
    </div>
  );
};

export default Overview;
