import React from 'react';
import './CaseStudies.css';
import img1 from "../../Webapplication/CaseStudies/Assets/2.jpeg";
import img2 from "../../Webapplication/CaseStudies/Assets/Webcase1.jpg";
import img3 from "../../Webapplication/CaseStudies/Assets/WEbCase2.jpg";

const CaseStudies = () => {
  return (
    <div className='container-fluid container_CaseStudies'id="CaseStudies">
      <div className='row'>
        <div className='col-lg-12 Case_Studies '>
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        <div className='col-lg-12 d-flex CaseStudies_Overall'>
          <div className="Case_all">
            <img
              src={img1}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1"><i className="bi bi-file-pdf-fill"></i>E BOOK</div>
              <h5>
              Custom E-Commerce Platform Development for Daily Sale Shop

              </h5>
              <p>
              Create a comprehensive e-commerce platform that facilitates the resale of products purchased.
              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img2}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1"><i className="bi bi-file-pdf-fill"></i>E BOOK</div>
              <h5>
              Integration of Multiple Payment Gateways:

              </h5>
              <p>
              Develop both web and mobile applications to provide a seamless shopping experience for customers.

Implement essential features for Admin, Customer, and Front Store.
              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img3}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1"><i className="bi bi-file-pdf-fill"></i>E BOOK</div>
              <h5>
              Mobile Responsiveness:

              </h5>
              <p>
              Ensuring the web platform was fully responsive to different screen sizes and that the mobile app provided a smooth user experience.
              .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CaseStudies;
