import React from "react";
import "./Aws.css";
import img1 from "../../Labs/Aws/Assets/1.jpg";
import img2 from "../../Labs/Aws/Assets/2.png";

const Aws = () => {
  return (
    <div className="container allthis">
      <div className="row">
        <div className="col-lg-4 contentt_001 py-4">
          <h1>Unmatched AWS Expertise</h1>
          <p>Migrate, Modernize & Optimize</p>
          <p className="text-muted">
            Accelerate your business and achieve your goals.
          </p>
          <button type="button" className="btn1111">
            Talk to an Expert<i className="bi bi-arrow-right"></i>
          </button>
          <div className="d-flex">
            <div className="col-lg-5 py-5">
              <img
                src={img2}
                alt=""
                style={{ width: "auto", height: "140px" }}
              />
            </div>
            <div className="col-lg-7 py-5">
              <p>Most Promising AWS</p>
              <p>Solutions Provider 2022</p>
              <p>-----------------------</p>
              <p>CIOREVIEW WINNER</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 contentt_001 py-4">
          <div className="ul_01">
            <h3>SDPS</h3>
            <ul>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> Amazon DynamoDB Delivery
              </li>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> Amazon Redshift Delivery
              </li>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> Amazon EMR Delivery
              </li>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> Amazon Open Search
              </li>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> Amazon Kinesis Delivery
              </li>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> Amazon QuickSight
              </li>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> Amazon EC2 for Windows Server Delivery
              </li>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> Amazon OpenSearch Service Delivery
              </li>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> Amazon RDS Delivery
              </li>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> AWS Glue Delivery
              </li>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> AWS Lambda Delivery
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-4 contentt_001 py-4">
          <div className="ul_01">
            <h3>COMPETENCIES</h3>
            <ul>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> Data & Analytics Services Competency
              </li>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> Microsoft Workloads Consulting Competency
              </li>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> DevOps Consulting Competency
              </li>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> APN Immersion Days
              </li>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> Migration Consulting Competency
              </li>
              <li className="list-group-item">
                <i className="bi bi-check2-circle"></i> AWS Well-Architected Partner Program
              </li>
            </ul>
          </div>
        </div>
        
      <div className=" col-lg-12 bottom_content ">
        <div className="col-lg-2 text-center">
          <h3>82</h3>
          <p>Net Promotor Score (NPS)</p>
        </div>
        <div className="col-lg-2 text-center">
          <h3>4.7</h3>
          <p>Customer Satisfaction Score (CSAT)</p>
        </div>
        <div className="col-lg-2 text-center">
          <h3>450+</h3>
          <p>AWS Certifications</p>
        </div>
        <div className="col-lg-2 text-center">
          <h3>850+</h3>
          <p>Employees</p>
        </div>
        <div className="col-lg-2 text-center">
          <h3>70+ PB</h3>
          <p>AWS Cloud data managed</p>
        </div>
        <div className="col-lg-2 text-center">
          <h3>82</h3>
          <p>Net Promotor Score (NPS)</p>
        </div>
      </div>
   
      </div>
      
    </div>
  );
};

export default Aws;
