import React from 'react'
import Header from '../../Components/Header/Header'
import Aligi from '../../Components/Labs/Aligi/Aligi'
import Customers from '../../Components/Customers/Customers'
import Offerlabs from '../../Components/Labs/Offerlabs/Offerlabs'
import Aws from '../../Components/Labs/Aws/Aws';
import StandOut from "../../Components/Labs/StandOut/StandOut"
import Modern from "../../Components/Labs/ModernData/Modern"
import ClientLab from "../../Components/LifeScience/ClientLabsTestimonial/ClientLab"
import Recentwork from '../../Components/Recentwork/Recentwork'
import FAQs from '../../Components/Labs/FAQs/FAQs'
import Talktous from '../../Components/Talktous/Talktous'
import Footer from '../../Components/Footer/Footer'

const Lab = () => {
  return (
    <div>
        <Header/>
      <Aligi/>
      <Customers/>
      <Offerlabs/>
      <Aws/>
      <br/>
      <StandOut/>
      <br/>
      <Modern/>
      <ClientLab/>
      <Recentwork/>
      <FAQs/>
      <Talktous/>
      <Footer/>




    
    </div>
  )
}

export default Lab
