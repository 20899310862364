import React, { useEffect } from "react";
import img1 from "../../Components/Header/Assests/1.jpg";
import "./Header.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { PopupButton } from "react-calendly";

const Header = () => {

  useEffect(() => {
    AOS.init();
    document.querySelector('.Header_Overall0001').style.marginTop = '1vh';

  }, [])

  return (
    <div className="container Header_Overall0001 fixed-top">
      <div className="row">
       
          <div className="col-lg-12 d-flex justify-content-between align-items-center">
            <div className="col=lg-2">
            <div className="imgaelogo" data-aos="fade-right">
              <a href="/">
                <img src={img1} alt=""  />
              </a>
            </div>
            </div>
            <div className="Header_Overall_Contantbox col-lg-7 gap-4" data-aos="fade-left">
            <div className="link1">
                <a className="dropdown-item" href="/">
                  Home
                </a>
              </div>
              <div className="link1">
                <a className="dropdown-item" href="/AboutUs">
                  About Us
                </a>
              </div>
              <div className="dropdown">
                <a
                  className="btn dropdown-toggle"
                  href="#"
                  role="button"
                  id="companyDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >

                  Industries
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="companyDropdown"
                >
                  <a className="dropdown-item" href="/Banking">
                    BFSI
                  </a>
                  <a className="dropdown-item" href="/Insurance">
                    Insurance
                  </a>
                  <a className="dropdown-item" href="/HealthCare">
                    HealthCare
                  </a>
                  <a className="dropdown-item" href="/HT">
                    Hi-Tech
                  </a>
                  <a className="dropdown-item" href="/Public">
                    Public Sector
                  </a>

                  <a className="dropdown-item" href="/Telecom">
                    TeleCommunication
                  </a>

                  <a className="dropdown-item" href="/ECommerce">
                    E-Commerce
                  </a>

                  <a className="dropdown-item" href="/Retail">
                    Retail & Wholesale
                  </a>
                  <a className="dropdown-item" href="/Global">
                    Global Capablility
                  </a>
                  <a className="dropdown-item" href="Energy">
                    Energy & Utilizes
                  </a>
                </div>
              </div>
              <div className="dropdown">
                <a
                  className="btn dropdown-toggle"
                  href="#"
                  role="button"
                  id="companyDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Services
                </a>
                <div className="dropdown-menu" aria-labelledby="companyDropdown">
                  <a className="dropdown-item" href="/Webdevelopment">
                    Web Enterprise Application Development
                  </a>
                  <a className="dropdown-item" href="/Mobile">
                    Mobile Application Development
                  </a>
                  <a className="dropdown-item" href="/AI">
                    Artificial intelligence Application Development
                  </a>
                  <a className="dropdown-item" href="#">
                    App Development & Maintenance
                  </a>
                  <a className="dropdown-item" href="DataandAnalyst">
                    Data & Analytics
                  </a>

                  <a className="dropdown-item" href="/Devops">
                    Devops and Agile
                  </a>  <a className="dropdown-item" href="/Testing">
                    Testing Services
                  </a>  <a className="dropdown-item" href="/Digital">
                    Digital Customer Experience
                  </a>  <a className="dropdown-item" href="/Cloud">
                    Cloud Security
                  </a>  <a className="dropdown-item" href="/Business">
                    Business Process Management
                  </a>
                </div>
              </div>
              
              
              <div className="link1">
                <a className="dropdown-item" href="/Peoplecarear">
                  Careers
                </a>
              </div>
              <div className="link1">
                <a className="dropdown-item" href="/ContactUs1">
                  Contact Us
                </a>
              </div>
              </div>
              <div className="col-lg-3">
                <PopupButton
                  url="https://calendly.com/dk-ldappsolutions"

                  rootElement={document.getElementById("root")}
                  text="BOOK  1 : 1 CALL "
                  className="btn02"
                />
              </div>
            
          </div>
       
      </div>
    </div>
  );
};

export default Header;
