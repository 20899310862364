import React from "react";
import Header from "../../Components/Header/Header";
import PublicSectorOverview from "../../Components/Industries/PublicSector/PublicSectorOverview/PublicSectorOverview";
import PublicSectorService from "../../Components/Industries/PublicSector/PublicSectorService/PublicSectorService";
import PublicSectorCaseStudies from "../../Components/Industries/PublicSector/PublicSectorCaseStudies/PublicSectorCaseStudies";
import PublicSectorConnect from "../../Components/Industries/PublicSector/PublicSectorConnect/PublicSectorConnect";
import Footer from "../../Components/Footer/Footer";

const Public = () => {
  return (
    <>
      <Header />
      <PublicSectorOverview />
      <PublicSectorService />
      <PublicSectorCaseStudies />
      <PublicSectorConnect />
      <Footer/>
    </>
  );
};

export default Public;
