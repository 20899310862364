import React from 'react'
import "./JoinOurTeam.css"
import img1 from "../../Components/JoinOurTeam/Assets/1.jpg";
import img2 from "../../Components/JoinOurTeam/Assets/2.jpg";
import img3 from "../../Components/JoinOurTeam/Assets/3.jpg";



const JoinOurTeam = () => {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-12 JoinOurTeam_row1'>
                    <div className='JoinOurTeam_content'>
                    <h1>Come Join Our Team</h1>
                    <h5>We're seeking driven individuals with a passion for problem-solving, client-centricity, continuous learning, and exceptional communication skills.You would collaborate closely with founders and industry leaders and solve impactful problems. </h5>
                    < a href="/Candicateprofile" className='JoinOurTeam_Button'>Get Started <i class="bi bi-arrow-up-right"></i></a>
                    </div>
                </div>
                <div className='row my-3 JoinOurTeam_row2'>
                    <div className='col-lg-5'>
                        <img src={img1} alt="" className='JoinOurTeam_image'/>
                    </div>
                    <div className='col-lg-5 JoinOurTeam_content1'>
                        <h1>Straatix Internship Programme</h1>
                        <h5>Embark on a transformative internship journey with our firm. Gain hands-on experience, work alongside experts, and make a real impact in 3-6 months. Join us to elevate your skills and launch your career. </h5>
                        < a href="/Candicateprofile" className='JoinOurTeam_Button'>Apply Now <i class="bi bi-arrow-up-right"></i></a>

                    </div>

                </div>
                <div className='row my-3 JoinOurTeam_row3'>
                   
                    <div className='col-lg-5 JoinOurTeam_content1'>
                        <h1>Our Vision</h1>
                        <h5>To help deliver innovation, impact and inclusivity in a globalized world. </h5>
                        < a href="/Candicateprofile" className='JoinOurTeam_Button'>Get Started <i class="bi bi-arrow-up-right"></i></a>

                    </div>
                    <div className='col-lg-5'>
                        <img src={img2} alt="" className='JoinOurTeam_image'/>
                    </div>

                </div>
                <div className='row my-3 JoinOurTeam_row4'>
                    <div className='col-lg-5'>
                        <img src={img3} alt="" className='JoinOurTeam_image'/>
                    </div>
                    <div className='col-lg-5 JoinOurTeam_content1'>
                        <h1>Our Mission</h1>
                        <h5>To help our clients build enduring culture, practices and systems in their global operations, while building a firm that attracts and motivates exceptional talent. </h5>
                        < a href="/Candicateprofile" className='JoinOurTeam_Button'>Apply Now <i class="bi bi-arrow-up-right"></i></a>

                    </div>

                </div>
            </div>
        </div>

    )
}

export default JoinOurTeam