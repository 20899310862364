import React from "react";
import "./TeleComOverview.css";

const TeleComOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="Content_Development15">
            <h1>TELECOMMUNICATION</h1>

            <p>
              Dynamic field that is constantly evolving with advancements in
              technology
            </p>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-lg-12 my-3">
          <div className="Button_Contain">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Services")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Services
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Case Studies")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Case Studies
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Connect")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Connect
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Blogs")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Blogs
            </button>
          </div>
        </div>
      </div>
      <div className="text-info">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-lg-12 my-5 Overview_content">
          <p>
            If you’re looking for Telecoms services designed with businesses in
            mind, then look no further than LDAPP's Telecoms. We are experts in
            the Telecoms industry, providing services to businesses.From
            business broadband to office phone line installation and
            maintenance, LDAPP's Telecoms can help make life easier for you,
            your company and its employees.
          </p>
          <p>
            With our commitment to excellence and client satisfaction, you can
            be sure that you will receive the highest quality Telecoms services
            when you work with LDAPP's Telecoms.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TeleComOverview;
