import React from "react";
import "./HTService.css";

const HTService = () => {
  return (
    <div className="container" id="Services">
      <div className="row">
        <div className="col-lg-12 d-flex">
          <div className="col-lg-4 BankServices_Content">
            <h3>IT SERVICES FOR THE NEXT GENERATION TECHNOLOGY PLATFORMS</h3>
            <p>
              Leading Software and Internet companies like Amazon, Google and
              Meta have chosen Optimum as a partner to drive disruption,
              accelerate innovation and deliver IT transformation.
            </p>
            <p>
              Software and Internet companies have been at the helm of the tech
              revolution that is now sweeping across industries. Today more than
              ever these companies need to deliver innovative solutions at
              breakneck speeds. Successful companies will be the ones that drive
              innovation and adapt to changes in the market.
            </p>
            <p>
              For that you need a partner like LDAPP who has the depth and
              breadth of technology expertise to support innovative business
              models, ensure cross-device availability of applications, and
              leverage Big Data to drive business growth.
            </p>
          </div>
          <div className="col-lg-8">
            <div className="d-flex">
              <div className="mobile_img1 py-5 col-lg-6">
                <h3 className="txt_01">ENTERPRISE AND PRODUCT TESTING</h3>
                <p className="txt_02">
                  Depending on the particular requirements and objectives of the
                  system or product being evaluated, enterprise testing and
                  product testing may both entail a range of testing techniques,
                  including functional testing, performance testing, usability
                  testing, and security testing.
                  <br />
                </p>
              </div>
              <div className="mobile_img2 py-5 col-lg-6">
                <h3 className="txt_01">CLOUD</h3>
                <p className="txt_02">
                  LDAPP provides service across consultancy, development,
                  migration, integration, or testing for the cloud
                  implementation of the Application
                  <br />
                </p>
              </div>
            </div>
            <div className=" mobile_img3 py-5 col-lg-12">
              <h3 className="txt_01">PRODUCT ENGINEERING SERVICES</h3>

              <p className="txt_02">
                The process of developing, creating, and maintaining digital
                goods including websites, mobile apps, and software programmes.
                <br />
                In order to assist organisations in developing and delivering
                successful digital goods, digital product development services
                may also include consultancy, user research, and other
                complementary services.
              </p>
            </div>
            <div className="d-flex">
              <div className="mobile_img4 py-5 col-lg-6">
                <h3 className="txt_01">DATA AND ANALYTICS</h3>
                <p className="txt_02">
                  LDAPP supports clients through identify the improvement
                  areas, prioritize the features and optimize the product.
                  Analyze user behavior, product usage, and customer feedback to
                  gain insights into how to improve the product’s features,
                  usability, and overall performance using multiple open source
                  and commercial analytics tools.
                  <br />
                </p>
              </div>
              <div className="mobile_img5 py-5 col-lg-6">
                <h3 className="txt_01">BUSINESS OPERATIONS MANAGEMENT</h3>
                <p className="txt_02">
                  LDAPP assists businesses in increasing productivity and
                  lowering expenses by centralising and standardising certain
                  business processes.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className=" BankServices_Content">
            <p>
              Whether it is Cloud, SaaS or IaaS implementation, Big Data &
              Analytics, Mobility Solutions, Platform Migration or Testing & QA
              services, our global delivery experts combine their deep domain
              experience with latest development methodologies to help you bring
              disruptive and futuristic innovations ahead of a changing market
              and remain competitive.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HTService;
