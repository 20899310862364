import React from "react";
import "./ClientTestimonals.css";
// import img2 form '../Assets/i3.webp'
import img2 from "../Testimonals/Assets/i3.webp";
import ball from "../Testimonals/Assets/13.png";

const ClientTestimonals = () => {
  return (
    <section>
      <div className="container bg">
        <div className="row">
          <div className="col-lg-12 ">
            <div className="text-center  py-3">
              <h1>Client Testimonials </h1>
            </div>
          </div>
        </div>

        <div className="row bginfo">
          <div className="border">

       
          <div className="col-lg-4 col-12 info">
            <div>
              <h1 className="bg1">$1.5M</h1>
              <h5 className="bg2">
                Achieved through Advanced Data Lakehouse Solution
              </h5>
            </div>

            <img src={ball} alt=""></img>

           
          </div>
          <div className="col-lg-8 col-12 info1   ">
            <div className=" ">
              <img src={img2} alt="" className="imgg "></img>

              <h4 className="txt py-5">
                {" "}
                <p>
                  {" "}
                  From Endless computing power, to reduce cycle and time and
                  easier data acess,the new efficency supported by Agilisium and
                  enabled by AWS helps us to produce timely insights about
                  products,customers and patiens and significantly enhance their
                  experiance
                </p>
              </h4>

              <span>
                <h5>Vishnu Maddileti</h5>
                <h6>Director data sciences,Amagen</h6>
              </span>
            </div>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientTestimonals;
