import React from 'react';
import './MobileCaseStudies.css';
import img1 from "../../Mobile/MobileCaseStudies/Assets/MobileCase1.webp";
import img2 from "../../Mobile/MobileCaseStudies/Assets/MobileCase2.jpg";
import img3 from "../../Mobile/MobileCaseStudies/Assets/MobileCase3.jpg";

const MobileCaseStudies = () => {
  return (
    <div className='container-fluid container_CaseStudies'id="CaseStudies">
      <div className='row'>
        <div className='col-lg-12 Case_Studies '>
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        <div className='col-lg-12 d-flex CaseStudies_Overall'>
          <div className="Case_all">
            <img
              src={img1}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1"><i className="bi bi-file-pdf-fill"></i>E BOOK</div>
              <h5>
              Building the plot

              </h5>
              <p>
              Brilliance is a skill which can be attained through efforts. Keeping the same in mind, the team started to prepare the wireframe for the application. The primary focus here was to make it viable for both, manufacturer and retailer.


              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img2}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1"><i className="bi bi-file-pdf-fill"></i>E BOOK</div>
              <h5>
              Bringing it together

              </h5>
              <p>
              Different sections were integrated into the interface to bring the best user experience for both; manufacturer and retailer. Also we took intense care from the design perspective..
              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img3}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1"><i className="bi bi-file-pdf-fill"></i>E BOOK</div>
              <h5>
              Not to miss anything

              </h5>
              <p>
              With the massive list of products to be integrated, the team was required to add the option to search products. This will help the user to surpass the long list of items and have a look at only those, he is interested in..
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileCaseStudies;
