import React from 'react'
import Header from '../../Components/Header/Header'

import Talktous from '../../Components/Talktous/Talktous'
import Footer from '../../Components/Footer/Footer'
import People from '../../Components/PeopleCareers/People'
import Peopleall from '../../Components/PeopleCareers/Peopleall/Peopleall'
import Peoplevalue from '../../Components/PeopleCareers/Peoplevalue/Peoplevalue'
import JoinOurTeam from '../../Components/JoinOurTeam/JoinOurTeam'
const Peoplecarear = () => {
  return (
    <div>
        <Header/>
       <People/>
       
      <JoinOurTeam/>
      <Peopleall/>
      <Peoplevalue/>
      <Footer/>
    




    
    </div>
  )
}

export default Peoplecarear
