import React from "react";
import Header from "../../Components/Header/Header";
import HTOverview from "../../Components/Industries/HT/HTOverview/HTOverview";
import HTService from "../../Components/Industries/HT/HTService/HTService";
import HTCaseStudies from "../../Components/Industries/HT/HTCaseStudies/HTCaseStudies";
import HTConnect from "../../Components/Industries/HT/HTConnect/HTConnect";
import Footer from "../../Components/Footer/Footer";

const HT = () => {
  return (
    <>
      <Header />
      <HTOverview />
      <HTService />
      <HTCaseStudies />
      <HTConnect/>
      <Footer/>

    </>
  );
};

export default HT;
