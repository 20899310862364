import React from "react";
import "./Offerlabs.css";

const Offerlabs = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h1>What we offer at Labs</h1>
        </div>
      </div>
      <div className="row py-3 d-flex justify-content-center">
        <div className="col-lg-5 box_o1 my-3 py-3 mx-2">
          <i className="bi bi-play-circle-fill"></i>
          <h4>Plug & Play Solutions Lab</h4>
          <p>
            We accelerate your go-to-market time with our <br />
            compelling ready-to-use solutions, helping you stay ahead of the
            competition.
          </p>
        </div>
        <div className="col-lg-5 box_o1 my-3 py-3 mx-2">
          <i className="bi bi-droplet-half"></i>
          <h4>Data-To-Insights Innovation Lab</h4>
          <p>
            Improve data-driven success by discovering tailored solutions for
            your data challenges at our Data-to-Insights Innovation Lab.{" "}
          </p>
        </div>
      </div>
      <div className="row py-3 d-flex justify-content-center">
        <div className="col-lg-5 box_o1 my-3 py-3 mx-2">
          <i className="bi bi-person-wheelchair"></i>
          <h4>Accelerators & Frameworks Lab</h4>
          <p>
            Ignite innovation with our accelerators and start building scalable
            solutions on customized frameworks to drive business growth.{" "}
          </p>
        </div>
        <div className="col-lg-5 box_o1 my-3 py-3 mx-2">
          <i className="bi bi-card-heading"></i>
          <h4>Technology Platform Innovation Lab</h4>
          <p>
            Agilisium's robust partnerships with AWS, Databricks, Snowflake,
            GCP, and Azure empowers you to optimize and innovate tech platforms.{" "}
          </p>
        </div>
      </div>
      <div className="row py-3 d-flex justify-content-center">
        
        <div className="col-lg-12 contact_btn my-3 py-3 mx-2">
        <button type="button" className="btn1111">
              Talk to an Expert<i class="bi bi-arrow-right"></i>
            </button>
        </div>
      </div>
    </div>
  );
};

export default Offerlabs;
