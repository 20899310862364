import React from 'react'
import Header from '../../Components/Header/Header'
import TeleComOverview from '../../Components/Industries/TeleCom/TeleComOverview/TeleComOverview'
import TeleComService from '../../Components/Industries/TeleCom/TeleComService/TeleComService'
import TeleComCaseStudies from '../../Components/Industries/TeleCom/TeleComCaseStudies/TeleComCaseStudies'
import TeleComConnect from '../../Components/Industries/TeleCom/TeleComConnect/TeleComConnect'
import Footer from '../../Components/Footer/Footer'

const TeleCom = () => {
  return (
    <>
      <Header/>
      <TeleComOverview/>
      <TeleComService/>
      <TeleComCaseStudies/>
      <TeleComConnect/>
      <Footer/>
    </>
  )
}

export default TeleCom
