import React from 'react'
import vedio3 from "../../Components/ContactUs/Assests/1.mp4"
import "./ContactUs.css"
const ContactUs = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="player_wrapper01">
            <video className="react_player01" src={vedio3} loop autoPlay muted>
              Your browser does not support HTML video.
            </video>
           
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs