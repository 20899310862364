import React from "react";
import img01 from "../../Components/Recentwork/Assests/1.jpg";
import img02 from "../../Components/Recentwork/Assests/2.jpg";
import img03 from "../../Components/Recentwork/Assests/3.jpg";
import "./Recentwork.css";

const Recentwork = () => {
  return (
    <div className="container">
        <div className="row">
            <div className="col-lg-12 d-flex justify-content-center align-items-center ">
               <h1>Our Most Recent Work</h1> 
            </div>

        </div>
      <div className="row">
        <div className="col-lg-4 d-flex justify-content-start align-items-center gap-5">
          <div className="overall_1">
            <img
              src={img01}
              alt=""
              style={{ width: "auto", height: "500px" }}
              className="image1"
            />
            <div className="overlay">
            <div className="icon_1"><i class="bi bi-file-pdf-fill"></i>E BOOK</div>
              <h5>
                How Log Management and Analytics with Amazon OpenSearch
                Serverless is revolutionizing Operational Analytics?
              </h5>
              <p >
                tempore, iusto itaque laboriosam molestiae ab, sapiente placeat
                inventore quaerat.
              </p>
            </div>
          </div>

          <div className="overall_1">
            <img
              src={img02}
              alt=""
              style={{ width: "auto", height: "500px" }}
              className="image1"
            />
            <div className="overlay">
            <div className="icon_2"><i class="bi bi-file-text-fill"></i>CASE STUDY</div>
              <h5>
                How Log Management and Analytics with Amazon OpenSearch
                Serverless is revolutionizing Operational Analytics?
              </h5>
              <p >
                tempore, iusto itaque laboriosam molestiae ab, sapiente placeat
                inventore quaerat.
              </p>
            </div>
          </div>

          <div className="overall_1">
            <img
              src={img03}
              alt=""
              style={{ width: "auto", height: "500px" }}
              className="image1"
            />
            <div className="overlay">
            <div className="icon_3"><i class="bi bi-file-earmark-break-fill"></i>BLOG</div>
                          <h5>
                How Log Management and Analytics with Amazon OpenSearch
                Serverless is revolutionizing Operational Analytics?
              </h5>
              <p>
                tempore, iusto itaque laboriosam molestiae ab, sapiente placeat
                inventore quaerat.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recentwork;
