import React from 'react'
import Header from '../../Components/Header/Header'
import ContactUs from '../../Components/ContactUs/ContactUs'
import Talktous from '../../Components/Talktous/Talktous'
import Footer from '../../Components/Footer/Footer'

const ContactUs1 = () => {
  return (
<>
<Header/>
<ContactUs/>
<Talktous/>
<Footer/>
</>
  )
}

export default ContactUs1