import React from 'react'
import './DataCaseStudies.css'
import img1 from "../../DataAnalyst/DataCaseStudies/Assets/DataCase1.webp";
import img2 from "../../DataAnalyst/DataCaseStudies/Assets/DataCase2.jpg";
import img3 from "../../DataAnalyst/DataCaseStudies/Assets/DataCase3.jpg";

const DataCaseStudies = () => {
  return (
    <div className='container-fluid container_CaseStudies'id="CaseStudies">
    <div className='row'>
      <div className='col-lg-12 Case_Studies '>
        <h1>LEADING OUR WAY</h1>
        <p>CASE STUDIES</p>
      </div>
      <div className='col-lg-12 d-flex CaseStudies_Overall'>
        <div className="Case_all">
          <img
            src={img1}
            alt=""
            style={{ width: "60vh", height: "500px" }}
            className="image_1"
          />
          <div className="Case_overlay">
            <div className="icon_1"><i className="bi bi-file-pdf-fill"></i>E BOOK</div>
            <h5>
            DEVOPS IMPLEMENTATION FOR A GLOBAL  BANK

            </h5>
            <p>
            LDAPP SOLUTIONS has partnered with clients in digital and DevOps initiatives to improve overall delivery efficiency and TAT through CI/CD automation.
            </p>
          </div>
        </div>
        <div className="Case_all">
          <img
            src={img2}
            alt=""
            style={{ width: "60vh", height: "500px" }}
            className="image_1"
          />
          <div className="Case_overlay">
            <div className="icon_1"><i className="bi bi-file-pdf-fill"></i>E BOOK</div>
            <h5>
            CREDIT MANGER APPLICATION DEVELOPMENT AND MAINTENANCE

            </h5>
            <p>
            The facilitates credit extensions and delivers a complete portfolio of credit risk management solutions and services which are widely used by the country’s banking and financial institutions,  large corporations, SMEs, legal firms and statutory bodies.
            </p>
          </div>
        </div>
        <div className="Case_all">
          <img
            src={img3}
            alt=""
            style={{ width: "60vh", height: "500px" }}
            className="image_1"
          />
          <div className="Case_overlay">
            <div className="icon_1"><i className="bi bi-file-pdf-fill"></i>E BOOK</div>
            <h5>
            TRADE FINANCE TRANSFORMATION

            </h5>
            <p>
            Legacy platforms with single application for deployment created huge resistance to new developments in case of organizational changes and/or business reengineering
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default DataCaseStudies
