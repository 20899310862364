import React, { useEffect } from "react";
import "./Companies.css";
import img1 from "../../Components/Companies/Assets/3.jpeg";
import vedio3 from "../../Components/Companies/Assets/5.mp4"
import AOS from "aos";
import "aos/dist/aos.css";
import ReactPlayer from 'react-player';


const Companies = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="container  Container_111 ">
      <div className="row py-3">
        <div className="col-lg-12 py-3" data-aos="fade-up-right">
          <h1>LDAPP AWS Capabilities</h1>
          <p>
            LDAPP is the fastest-growing Cloud Transformation & Data
            Analytics company with strong expertise in Data lake solutions, Data
            Warehouse Engineering, Data Migration & Modernization.
          </p>
        </div>
        <div className="row py-3">
          <div className="col-lg-12 d-flex">
            <div className="col-lg-9 ">
              <div className="title011 py-3">
                <h4>Competencies</h4>
              </div>
              <div className="d-flex justify-content-center align-items-center py-3">
                <ul className="1" data-aos="flip-left">
                  <li className="list-group-item">
                    <i
                      className="bi bi-check2-circle"
                      style={{ color: "red" }}
                    ></i>{" "}
                    Data & Analytics Consulting Competency
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <i
                      className="bi bi-check2-circle"
                      style={{ color: "red" }}
                    ></i>
                    Microsoft Workloads Consulting Competency
                  </li>
                </ul>
                <ul
                  className="style={{ listStyleType: 'none', padding: 0 }}"
                  data-aos="flip-right"
                >
                  <li className="list-group-item">
                    {" "}
                    <i
                      className="bi bi-check2-circle"
                      style={{ color: "red" }}
                    ></i>
                    DevOps Consulting Competency
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <i
                      className="bi bi-check2-circle"
                      style={{ color: "red" }}
                    ></i>
                    APN Immersion Days
                  </li>
                </ul>
                <ul
                  className="style={{ listStyleType: 'none', padding: 0 }}"
                  data-aos="flip-right"
                >
                  <li className="list-group-item">
                    {" "}
                    <i
                      className="bi bi-check2-circle"
                      style={{ color: "red" }}
                    ></i>
                    Migration Consulting Competency
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <i
                      className="bi bi-check2-circle"
                      style={{ color: "red" }}
                    ></i>
                    AWS Well-Architected Partner Program
                  </li>
                </ul>
              </div>

              <div className="title011">
                <h4>SDPs</h4>{" "}
              </div>
              <div className="d-flex  align-items-center py-3">
                <ul
                  className="style={{ listStyleType: 'none', padding: 0 }}"
                  data-aos="flip-right"
                >
                  <li className="list-group-item">
                    {" "}
                    <i
                      className="bi bi-check2-circle"
                      style={{ color: "red" }}
                    ></i>
                    Amazon Kinesis Delivery
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <i
                      className="bi bi-check2-circle"
                      style={{ color: "red" }}
                    ></i>
                    Amazon DynamoDB Delivery
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <i
                      className="bi bi-check2-circle"
                      style={{ color: "red" }}
                    ></i>
                    Amazon RDS Delivery
                  </li>
                  <li className="list-group-item">
                    <i
                      className="bi bi-check2-circle"
                      style={{ color: "red" }}
                    ></i>
                    AWS Lambda Delivery
                  </li>
                </ul>
                <ul
                  className="style={{ listStyleType: 'none', padding: 0 }}"
                  data-aos="flip-right"
                >
                  <li className="list-group-item">
                    <i
                      className="bi bi-check2-circle"
                      style={{ color: "red" }}
                    ></i>
                    Amazon EMR Delivery
                  </li>
                  <li className="list-group-item">
                    <i
                      className="bi bi-check2-circle"
                      style={{ color: "red" }}
                    ></i>
                    Amazon EC2 for Windows Server Delivery
                  </li>
                  <li className="list-group-item">
                    <i
                      className="bi bi-check2-circle"
                      style={{ color: "red" }}
                    ></i>
                    Amazon OpenSearch Service Delivery{" "}
                  </li>
                </ul>
                <ul
                  className="style={{ listStyleType: 'none', padding: 0 }}"
                  data-aos="flip-right"
                >
                  <li className="list-group-item">
                    <i
                      className="bi bi-check2-circle"
                      style={{ color: "red" }}
                    ></i>
                    Amazon Redshift Delivery
                  </li>
                  <li className="list-group-item">
                    <i
                      className="bi bi-check2-circle"
                      style={{ color: "red" }}
                    ></i>
                    Amazon QuickSight
                  </li>
                  <li className="list-group-item">
                    <i
                      className="bi bi-check2-circle"
                      style={{ color: "red" }}
                    ></i>
                    AWS Glue Delivery
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-lg-3 Womens_3"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <ReactPlayer
              className='reactplayer01'
              url={vedio3}
              width="100%"
              height='80vh'
              playing={true} 
              loop={true}    
              muted={true}   
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Companies;
