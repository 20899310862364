import React from "react";
import "./EnergyService.css";

const EnergyService = () => {
  return (
    <div className="container" id="Services">
      <div className="row">
        <div className="col-lg-12 d-flex">
          <div className="col-lg-4 BankServices_Content">
            <h3>Dedicated Utility Software Solutions Services</h3>
            <p>
              We work with energy software development experts, energy equipment
              manufacturers, and utility corporations to develop scalable
              software products that give complete control over the distribution
              of energy. We develop comprehensive utility software solutions to
              track the functionality of power transmission machinery and
              guarantee effective utility use in residential homes, business
              buildings, and industrial sites.
            </p>
            <p>
              Our energy management software developers are experts in
              custom-tailored cloud-based utility tracking energy software to
              assist utility businesses with documentation, invoicing &
              performance monitoring that helps in accurately capturing data and
              streamlining company operations.
            </p>
          </div>
          <div className="col-lg-8">
            <div className="d-flex">
              <div className="mobile_img1 py-5 col-lg-6">
                <h3 className="txt_01">Utility Asset Management</h3>
                <p className="txt_02">
                  custom-tailored cloud-based utility tracking energy software
                  to assist utility businesses with documentation
                  <br />
                </p>
              </div>
              <div className="mobile_img2 py-5 col-lg-6">
                <h3 className="txt_01">Energy Portfolio Management</h3>
                <p className="txt_02">
                  Our customized energy and utilities solutions help you to
                  monitor and manage your energy consumption in real-time
                  <br />
                </p>
              </div>
            </div>
            <div className=" mobile_img3 py-5 col-lg-12">
              <h3 className="txt_01">Custom Smart Grid Technology Software</h3>

              <p className="txt_02">
                With the development of cutting-edge technologies for grid
                distribution, transmission, consumption processes, and more, our
                developers help improve existing systems and offer customized
                energy and utilities solutions
                <br />
              </p>
            </div>
            <div className="d-flex">
              <div className="mobile_img4 py-5 col-lg-6">
                <h3 className="txt_01">Smart Grid Meter Programming</h3>
                <p className="txt_02">
                  We design advanced metering infrastructure, data management,
                  two-way communication, automatic meter reading
                  <br />
                </p>
              </div>
              <div className="mobile_img5 py-5 col-lg-6">
                <h3 className="txt_01">Location-Based Services</h3>
                <p className="txt_02">
                  We offer a complete set of technologies to support the
                  implementation of smart grids and smart cities.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className=" BankServices_Content">
            <p>
              Our energy management systems centralize your complete energy
              portfolio by monitoring and predicting utility consumption,
              reducing costs, and balancing energy needs. Our customized energy
              and utilities solutions help you to monitor and manage your energy
              consumption in real-time, helping you efficiently manage your
              energy portfolio.
            </p>
            <p>
              Utilize cutting-edge technology, cloud infrastructure, and linked
              devices to integrate building operations for accurate control of
              energy-related service plants. By integrating sensors with smart
              city platforms, our latest smart city software development
              services assist you with daily functions such as light control,
              air pollution, and floods related hazards
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnergyService;
