import React from "react";
import "./Genomics.css";
import img1 from "../../LifeScience/Genomics/Assets/1.png";
import img2 from "../../LifeScience/Genomics/Assets/2.png";
import img3 from "../../LifeScience/Genomics/Assets/3.png";
import img4 from "../../LifeScience/Genomics/Assets/4.png";

const Genomics = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 d-flex flex-wrap justify-content-center">
          <div className="col-lg-6 ">
            <div className="col-lg-12 d-flex justify-content-center">
              <div className="image-container image_container1">
                <img src={img1} alt="Pharma" className="image" />
                <p>Pharma</p>
              </div>
              <div className="image-container image_container2">
                <img src={img2} alt="Clinical Trials" className="image" />
                <p>Clinical Trials</p>
              </div>
            </div>
            <div className="col-lg-12 d-flex justify-content-center">
              <div className="image-container image_container3">
                <img src={img3} alt="Medical Devices" className="image" />
                <p>Medical Devices</p>
              </div>
              <div className="image-container image_container4">
                <img src={img4} alt="Genomics" className="image" />
                <p>Genomics</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <h4>Genomics</h4>
            <p>
              In general terms, Genomics is a sector that concentrates on
              studying the structure, evolution, and functioning of DNA genomes.
              Cloud has been at the forefront of innovation in genomics in the
              following ways.
            </p>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                   Pharmacogenomics
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                  Pharmacogenomics is the study of analyzing genes responsible for variable actions that drugs trigger. Cloud solutions enable researchers to draw more refined pharmacogenomic results in lesser time.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Tertiary Analysis
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                  Transform genomic data into biological interpretations with the help of potent machine learning and high-performance cloud computing resources. Ask Agilisium for help today.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                   Clinical Genomics
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                  Data analytics for life sciences grant clinical professionals the power to diagnose with utmost precision using better genetic data. AWS has been the leading choice for safe and fast clinical genomics for years.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                   Multi-modal and Multi-omics
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                  Experience increased computer efficiency at scale and achieved high data integration capabilities, making discovering clinical data correlations easier than ever with multi-modal and multi-omics.                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-3 my-3">
        <div className="row py-3 my-3">
            <div className="col-lg-12 py-3 my-3">
                <h1>Accelerate the Life Sciences Data Transformation Journey!</h1>
                <p>Agilisium is a leading provider of cloud and data solutions, catering to a global clientele of Life Science organizations. Leverage the perfect blend of cloud and data analytics for life sciences today with our trusted expertise:</p>

            </div>
            <div className="container my-4">
      <div className="row">
        <div className="col-lg-12 d-flex flex-wrap justify-content-between mb-4">
          <div className="col-lg-4 col-md-6 col-sm-12 box_01 mb-4">
            <div className="d-flex align-items-center mb-2">
              <i className="bi bi-bug-fill me-2"></i>
              <h4>Advanced Business Intelligence</h4>
            </div>
            <p>Unleash the power of big data analysis for life sciences with collaborative advanced business intelligence and EPM optimization tools.</p>
          </div>
          
          <div className="col-lg-4 col-md-6 col-sm-12 box_01 mb-4">
            <div className="d-flex align-items-center mb-2">
              <i className="bi bi-clipboard-heart-fill me-2"></i>
              <h4>Commercial Data Lake Platforms</h4>
            </div>
            <p>Build a centralized repository of personalized life sciences data to store and structure everything in one place. With Agilisium cloud, there are no virtual limitations to possibilities.</p>
          </div>
          
          <div className="col-lg-4 col-md-6 col-sm-12 box_01 mb-4">
            <div className="d-flex align-items-center mb-2">
              <i className="bi bi-bug-fill me-2"></i>
              <h4>Data-driven R&D</h4>
            </div>
            <p>Harness the power of data with cloud-driven lab informatics to get intricate life science insights for human-centered R&D. This cloud data will help you govern your business processes better.</p>
          </div>
        </div>

        <div className="col-lg-12 d-flex flex-wrap justify-content-between">
          <div className="col-lg-4 col-md-6 col-sm-12 box_01 mb-4">
            <div className="d-flex align-items-center mb-2">
              <i className="bi bi-bug-fill me-2"></i>
              <h4>Advanced Business Intelligence</h4>
            </div>
            <p>Unleash the power of big data analysis for life sciences with collaborative advanced business intelligence and EPM optimization tools.</p>
          </div>
          
          <div className="col-lg-4 col-md-6 col-sm-12 box_01 mb-4">
            <div className="d-flex align-items-center mb-2">
              <i className="bi bi-clipboard-heart-fill me-2"></i>
              <h4>Commercial Data Lake Platforms</h4>
            </div>
            <p>Build a centralized repository of personalized life sciences data to store and structure everything in one place. With Agilisium cloud, there are no virtual limitations to possibilities.</p>
          </div>
          
          <div className="col-lg-4 col-md-6 col-sm-12 box_01 mb-4">
            <div className="d-flex align-items-center mb-2">
              <i className="bi bi-bug-fill me-2"></i>
              <h4>Data-driven R&D</h4>
            </div>
            <p>Harness the power of data with cloud-driven lab informatics to get intricate life science insights for human-centered R&D. This cloud data will help you govern your business processes better.</p>
          </div>
        </div>
      </div>
    </div>

        </div>

      </div>
    </div>
  );
};

export default Genomics;
