import React from "react";
import Header from "../../Components/Header/Header";
import InsuranceService from "../../Components/Industries/Insurance/InsuranceService/InsuranceService";
import InsuranceOverview from "../../Components/Industries/Insurance/InsuranceOverview/InsuranceOverview";
import InsuranceCaseStudies from "../../Components/Industries/Insurance/InsuranceCaseStudies/InsuranceCaseStudies";
import InsuranceConnect from "../../Components/Industries/Insurance/InsuranceConnect/InsuranceConnect";
import Footer from "../../Components/Footer/Footer";

const Insurance = () => {
  return (
    <>
      <Header />
      <InsuranceOverview />
      <InsuranceService />
      <InsuranceCaseStudies />
      <InsuranceConnect />
      <Footer/>
    </>
  );
};

export default Insurance;
