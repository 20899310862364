import React from 'react'
import './ClientLab.css'
// import Building1 from '../Assets/building.jpg'


const ClientLab = () => {
    return (
        <section>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 col-12'>
                        <div className='texter'>
                            <h1>Client Testimonials</h1>

                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-4   ab rounded-start-2 '>
                        <div className='top_det'>
                            {/* <img src={Building1} alt=''  className='bg5'></img> */}

                            <h1 className='text_alt py-3'>$1.5M</h1>
                            <p className='text_alt py-3' >Achieved through  Advanced Data Lakehouse  Solution</p>

                        </div>
                    </div>

                    <div className='col-lg-8  ab1 rounded-end'>
                        <div className='text1 py-5'>

                            <h3> <p>From endless computing powe, to reduced cycle time
                                and easier<br></br> data acess,the new efficency supported by Agilisum
                                and enabled by AWS helps us to produce timely <br></br>insights about
                                products, Customers, and patients and signficantaly enhance their
                                experiences.
                            </p></h3>

                            <span className='text_2 py-6'>
                                <h5>Vishnu Maddileti</h5>
                                <h6>Director data sciences,Amagen</h6>
                            </span>
                        </div>



                    </div>

                </div>
            </div>



        </section>
    )
}

export default ClientLab
