import React from "react";
import "./EnergyCaseStudies.css";
import img1 from "../../Banking/BankCaseStudies/Assets/1 (1).jpeg";
import img2 from "../../Banking/BankCaseStudies/Assets/2.jpeg";
import img3 from "../../Banking/BankCaseStudies/Assets/3.jpeg";

const EnergyCaseStudies = () => {
  return (
    <div className="container-fluid container_CaseStudies" id="Case Studies">
      <div className="row">
        <div className="col-lg-12 Case_Studies ">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        <div className="col-lg-12 d-flex CaseStudies_Overall">
          <div className="Case_all">
            <img
              src={img1}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>Enterprise Solutions</h5>
              <p>
                Let us assist You in implementing and cutting-edge enterprise asset and service management Solution driven by AI and Preductive analytics.
                implementing the most recent ERP and EAM Software.
              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img2}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>Enterprise Asset Management</h5>
              <p>
                Improve Your Grids and data-analytics and predective maintanance-driven Intellegent electric Utility asset management Software.
                Gaining a real a real-time understanding of the functioning of your asset. 
              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img3}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>Business Intelligence & Performance Management</h5>
              <p>
                Our data Scientists and analyst can help you transform your raw data into valuable insights .Our business inteligience and alalytics team 
                for customized energy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnergyCaseStudies;
