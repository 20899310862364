import React from "react";
import "./TestingCapabilities.css";
import img1 from "./Assets/img1.jpg";
import img2 from "./Assets/img2.jpg";
import img3 from "./Assets/img3.jpg";
import img4 from "./Assets/img4.jpg";

const TestingCapabilities = () => {
  return (
    <div className="container-fluid " id="Capabilities">
      <div className="row">
        <div className="col-lg-12">
          <div>
            <h1>TESTING SERVICES CAPABILITIES</h1>
          </div>
        </div>
        <div className="col-lg-12 Service_Content_01 my-5 ">
          <div className="col-lg-7 mx-3">
            <div className="Services_content01">
              <h3>BUSINESS CONTINITY AND RESILIENCE TESTING</h3>
              <p>
                The goal of this testing is to ensure that the organization’s
                critical business functions can continue to operate and that the
                organization can return to normal operations as quickly as
                possible.
              </p>
              <hr />
              <ul className="icon_li">
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i> Business continuity
                  and resilience testing typically involve simulating a disaster
                  scenario and evaluating the organization’s ability to respond
                  and recover.
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-laughing"></i> This can include
                  testing the organization’s contingency plans, failover
                  systems, backup systems, and other recovery processes.
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i> The results of the
                  testing can be used to identify any weaknesses or gaps in the
                  organization’s ability to recover from a disruptive event and
                  to implement improvements to increase the organization’s
                  resilience.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 Image_service01">
            <img src={img1} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="col-lg-12 Service_Content_02 ">
          <div className="col-lg-5 Image_service02">
            <img src={img2} alt="" />
          </div>
          <div className="col-lg-7  ">
            <div className="Services_content02 ">
              <h3>DIGITAL AND EXPERIENCE TESTING</h3>
              <p>
                Digital testing is the process of evaluating the performance,
                functionality, and user experience of digital products, such as
                websites, mobile apps, and software applications. It is
                typically focused on testing the user interface (UI), user
                experience (UX), and overall usability of the product.
              </p>
              <hr />
              <ul className="icon_li">
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i> Experience testing
                  is a broader term that can refer to any type of testing that
                  is focused on evaluating the user experience of a product or
                  service.
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-laughing"></i>This can include
                  digital testing, as well as testing of physical products,
                  customer service, and other customer-facing aspects of a
                  business.
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i> In both digital testing
                  and experience testing, the goal is to identify and address
                  any issues or challenges that may impact the user’s ability to
                  effectively use or engage with the product or service.
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i>This can include
                  testing for technical issues, such as bugs or compatibility
                  issues, as well as testing for usability and user
                  satisfaction.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-12 Service_Content_01 my-5">
          <div className="col-lg-7 mx-3">
            <div className="Services_content01">
              <h3>APPLICATION MODERNIZATION TESTING</h3>
              <p>
                Application modernization testing is the process of evaluating
                the performance, functionality, and stability of an application
                that has undergone modernization. Modernization typically
                involves updating an application to make it more efficient,
                scalable, or secure, or adding new features or capabilities.
                <br />
              </p>
              <hr />
              <ul className="icon_li">
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i>
                  During application modernization testing, the updated
                  application is subjected to a series of tests to ensure that
                  it meets the desired outcomes of the modernization project.
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-laughing"></i>This can include
                  functional testing to ensure that the application performs as
                  intended, load testing to ensure that it can handle expected
                  levels of usage, and regression testing to ensure that the
                  modernization process has not introduced any new errors or
                  caused existing functionality to break.
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i>The goal of application
                  modernization testing is to identify and address any issues or
                  challenges that may impact the performance or usability of the
                  modernized application and to ensure that it meets the needs
                  of the users and the business.
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i>Regenerate response.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 Image_service01">
            <img src={img3} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="col-lg-12 Service_Content_02 ">
          <div className="col-lg-5 Image_service02">
            <img src={img4} alt="" />
          </div>
          <div className="col-lg-7  ">
            <div className="Services_content02 ">
              <h3>CYBER AND DATA SECURITY</h3>
              <p>
                Cybersecurity testing is the process of evaluating the security
                of a computer system, network, or application to identify
                vulnerabilities that could be exploited by cybercriminals. The
                goal of cybersecurity testing is to protect against unauthorized
                access, data breaches, and other cyber threats.
                <br />
              </p>
              <hr />
              <p>
                There are several types of cybersecurity testing, including:
              </p>
              <ul className="icon_li">
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i> Vulnerability
                  assessment: This involves identifying and evaluating
                  vulnerabilities in a system, such as weak passwords or
                  unpatched software.
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-laughing"></i> Penetration testing:
                  Also known as “pen testing,” this involves simulating an
                  attack on a system to identify vulnerabilities and assess the
                  organization’s ability to detect and defend against such
                  attacks.
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i>Security controls
                  testing: This involves evaluating the effectiveness of
                  security controls, such as firewalls or intrusion detection
                  systems, to ensure that they are working as intended.
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i>Network security testing:
                  This involves testing the security of a network, including
                  evaluating the security of devices, protocols, and
                  communication channels.
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i>Application security
                  testing: This involves evaluating the security of an
                  application, including testing for vulnerabilities such as SQL
                  injection attacks or cross-site scripting (XSS) attacks.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestingCapabilities;
