import React from "react";
import "./AICapabilities.css";
import img1 from "../../AI/AICapabilities/Assets/AI3.jpg";
import img2 from "../../AI/AICapabilities/Assets/AI2.jpg";
import img3 from "../../AI/AICapabilities/Assets/AI4.jpg";
import img4 from "../../AI/AICapabilities/Assets/AI6.png";

const AICapabilities = () => {
  return (
    <div className="container-fluid "id="Capabilities" >
      <div className="row">
        <div className="col-lg-12">
          <div>
            <h1>DATA AND ANALYTICS CAPABILITIES
            </h1>
          </div>
        </div>
        <div className="col-lg-12 Service_Content_01 my-5 ">
          <div className="col-lg-7 mx-3">
            <div className="Services_content01">
              <h3>SERVICES WE OFFER</h3>
              <p>
              {/* Since the palette of possibilities AI presents before the global business scenario is so diverse and huge, it is essential that you need to be versatile with AI implementations in your business; and that is where LDAPP comes in. Embark on a journey of symbiotic evolution of intelligence, and let us help you fulfill your AI aspirations. Join hands to learn and adapt to the latest AI trends. With our technological mastery, hands-on experience in handling AI projects, and your novel designs combined, let us strive forward to accomplish our goals. */}
</p>
<p>
From face recognition to object recognition that instantaneously analyse defects in industrial products, we provide AI services that integrate into your business with ease.
</p>
<p>
Track individuals, interpret and predict customer emotions to build a data-driven decision process and get more conversions, sales, and profits.
</p>
<p>
Enable quick and easy verification of codes, optical character and object with Digital Image processing that ensures reliable identification.

</p>

              <hr />
              <ul className="icon_li">
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i> Technology
                  Consultation & Architecture Assessment
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-laughing"></i> Proof of concept
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i> Center of Excellence
                  (CoE) Setup
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 Image_service01">
            <img src={img1} alt=""  className="img-fluid"/>
          </div>
        </div>
        <div className="col-lg-12 Service_Content_02 ">
          <div className="col-lg-5 Image_service02">
            <img src={img4} alt="" />
          </div>
          <div className="col-lg-7  ">
            <div className="Services_content02 ">
              <h3>Developing AI Services Using Market-leading Technologies
              </h3>
              <p>
              Since the palette of possibilities AI presents before the global business scenario is so diverse and huge, it is essential that you need to be versatile with AI implementations in your business; and that is where ThinkPalm comes in. Embark on a journey of symbiotic evolution of intelligence, and let us help you fulfill your AI aspirations. Join hands to learn and adapt to the latest AI trends. With our technological mastery, hands-on experience in handling AI projects, and your novel designs combined, let us strive forward to accomplish our goals.
</p>
              <hr />
              <ul className="icon_li">
                
                <li className="list-group-item">
                  <i className="bi bi-emoji-laughing"></i>PUBLIC SECTOR

                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i> HEALTHCARE

                  development
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i> BANKING

                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-12 Service_Content_01 my-5">
          <div className="col-lg-7 mx-3">
            <div className="Services_content01">
              <h3>Data Modernization Services
              </h3>
              <p>
              Make your data more valuable and create advanced data products for intelligent business decisions with the transformative powers of modernized data architecture in the cloud.
              <br />
                We help you accelerate the adoption of a future-ready data foundation that removes barriers and enhances data accessibility, accuracy, and timeliness. With our data frameworks, deep expertise, and robust partner ecosystem of leading hyperscalers and data technology providers, we help you build new data-driven capabilities and prepare your business to get the maximum value of AI.
             </p>
              <hr />
              <ul className="icon_li">
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i>
                  Driving banking and financial services with data and AI

                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-laughing"></i> Defining the journey to AIOps

                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i>Moving beyond data basics to data-driven transformation

                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 Image_service01">
            <img src={img3} alt=""  className="img-fluid" />
          </div>
        </div>
        <div className="col-lg-12 Service_Content_02 ">
          <div className="col-lg-5 Image_service02">
            <img src={img2} alt="" />
          </div>
          <div className="col-lg-7  ">
            <div className="Services_content02 ">
              <h3>Enterprise AI Services
              </h3>
              <p>
              Leverage the power of AI to accelerate business outcomes. Embed AI technology components and AI-generated insights into your business decisions and processes. Whether you want to personalize delightful customer experiences at scale or you want to embed AI into every IT process and drive speed and efficiency, our solutions can unlock a new level growth for you
                <br />
                We manage end-to-end AI services both for business operations and IT management. Our solutions accelerate AI discovery and pilot development providing your teams the confidence to innovate. And powered by a scalable AI framework that is built on a solid data foundation leveraging pre-built assets, AutoML, transfer learning, MLOps, synthetic data generation – we can minimize time-to-market and maximize business value.


              </p>
              <hr />
              <ul className="icon_li">
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i> EAI using the
                  leading-edge products
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-laughing"></i> Integration
                  Maintenance
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i> Version migration
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AICapabilities;
