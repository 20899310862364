import React from "react";
import "./RetailCaseStudies.css";
import img1 from "../RetailCaseStudies/Assets/Retail2.jpg";

const RetailCaseStudies = () => {
  return (
    <div className="container-fluid container_CaseStudies" id="Case Studies">
      <div className="row">
        <div className="col-lg-12 Case_Studies ">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        <div className="col-lg-12 d-flex CaseStudies_Overall">
          <div className="Case_all">
            <img
              src={img1}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>PROCESS AUTOMATION USING UI PATH FOR AN AUTOMOTIVE GIANT</h5>
              <p>
              European Multinational automotive corporation desires to automate processes using UI Path RPA technology in a managed services model to ensure end to end ownership and uninterrupted quality services.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetailCaseStudies;
