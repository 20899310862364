import React from 'react';
import './ALCaseStudies.css';
import img1 from "../../AI/AICaseStudies/Assets/AICas1.jpeg";
import img2 from "../../Webapplication/CaseStudies/Assets/2.jpeg";
import img3 from "../../AI/AICaseStudies/Assets/AICase2.jpg";

const AICaseStudies = () => {
  return (
    <div className='container-fluid container_CaseStudies'id="CaseStudies">
      <div className='row'>
        <div className='col-lg-12 Case_Studies '>
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        <div className='col-lg-12 d-flex CaseStudies_Overall'>
          <div className="Case_all">
            <img
              src={img1}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1"><i className="bi bi-file-pdf-fill"></i>E BOOK</div>
              <h5>
              Artificial Intelligence in Healthcare
              </h5>
              <p>
              AI has the ability to change the healthcare system by producing new and crucial insights into the vast amount of digital data that it can access far more quickly and efficiently than any human can..
              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img2}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1"><i className="bi bi-file-pdf-fill"></i>E BOOK</div>
              <h5>
              Developing AI Services Using Market-leading Technologies

              </h5>
              <p>
              Artificial intelligence, or AI, is the theory and development of computer systems that are able to perform tasks normally required by human intelligence.
              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img3}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1"><i className="bi bi-file-pdf-fill"></i>E BOOK</div>
              <h5>
              SERVICES WE OFFER

              </h5>
              <p>
              From face recognition to object recognition that instantaneously analyse defects in industrial products, we provide AI services that integrate into your business with ease.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AICaseStudies;
