import React from "react";
import "./HTCaseStudies.css";
import img1 from "../HTCaseStudies/Assets/HitechCase2.jpg"
import img2 from "../HTCaseStudies/Assets/HitechCase3.jpg"
const HTCaseStudies = () => {
  return (
    <div className="container-fluid container_CaseStudies" id="Case Studies">
      <div className="row">
        <div className="col-lg-12 Case_Studies ">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        <div className="col-lg-12 d-flex CaseStudies_Overall">
          <div className="Case_all">
            <img
              src={img1}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>
                BUILDING CORE APPLICATION FOR LEASING AND RELATED FINANCIAL
                SERVICES
              </h5>
              <p>
              finance lease for small and medium-sized companies. The scope of engagement includes assessment of IT systems landscape, to-be architecture, grand design and phased construction.
              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img2}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>CREDIT MANGER APPLICATION DEVELOPMENT AND MAINTENANCE</h5>
              <p>
              credit risk management solutions and services which are widely used by the country’s banking and financial institutions, insurance and telecommunication companies, large corporations, SMEs, legal firms and statutory bodies.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HTCaseStudies;
