import React from "react";
import "./ECommerceService.css";

const ECommerceService = () => {
  return (
    <div className="container" id="Services">
      <div className="row">
        <div className="col-lg-12 d-flex">
          <div className="col-lg-4 BankServices_Content">
            <h3>E-COMMERCE SERVICES</h3>
            <p>
              E-commerce, or electronic commerce, involves buying and selling
              goods and services over the internet. It includes various models
              like B2C, B2B, C2C, C2B, and D2C, each catering to different types
              of transactions. Key components of e-commerce include online
              storefronts, secure payment systems, efficient logistics, and
              robust customer service.
            </p>
            <p>
              The benefits of e-commerce include convenience, global reach,
              cost-effectiveness, personalization, and scalability, though it
              faces challenges such as security concerns, competition, logistics
              issues, and the need for customer trust.
            </p>
          </div>
          <div className="col-lg-8">
            <div className="d-flex">
              <div className="mobile_img1 py-5 col-lg-6">
                <h3 className="txt_01">Catalogue Management</h3>
                <p className="txt_02">
                  Our services include catalogue updating maintenance,
                  conversion and data indexing for online use or offline
                  database/inventory management.
                  <br />
                </p>
              </div>
              <div className="mobile_img2 py-5 col-lg-6">
                <h3 className="txt_01">Logistic Management</h3>
                <p className="txt_02">
                  Support for transportation management, inventory management,
                  materials handling and order fulfilment.
                  <br />
                </p>
              </div>
            </div>

            <div className="d-flex">
              <div className="mobile_img4 py-5 col-lg-6">
                <h3 className="txt_01">Return and Refund Management</h3>
                <p className="txt_02">
                  Our services include coordinating with customers, logistics
                  partners and sellers
                  <br />
                </p>
              </div>
              <div className="mobile_img5 py-5 col-lg-6">
                <h3 className="txt_01">Loyalty Programs</h3>
                <p className="txt_02">
                  We help design and administer our clients’ loyalty programs
                  and provide seller support services.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className=" BankServices_Content">
            <p>
              E-commerce services encompass various aspects to facilitate online
              transactions. They include online storefronts where businesses
              list products for sale and payment gateways like PayPal and Stripe
              for secure transactions. Shopping cart systems allow customers to
              manage multiple purchases, while order management ensures timely
              fulfillment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ECommerceService;
