import React from 'react'
import Header from '../../Components/Header/Header'
import Vedio from "../../Components/LifeScience/Vedio/Vedio"
import Genomics from '../../Components/LifeScience/Genomics/Genomics'
import ClientLab from '../../Components/LifeScience/ClientLabsTestimonial/ClientLab'
import Ourcase from '../../Components/LifeScience/Ourcase/Ourcase'
import ClientTestimonals from "../../Components/Testimonals/ClientTestimonals"
import BLOG from "../../Components/LifeScience/BLOG/BLOG"
import Recentwork from '../../Components/Recentwork/Recentwork'
import Talktous from '../../Components/Talktous/Talktous'
import Footer from '../../Components/Footer/Footer'


const LifeScience = () => {
  return (
    <div>
      <Header/>
      <Vedio/>
      <Genomics/>
      <ClientLab/>
      <Ourcase/>
      <ClientTestimonals/>
      <BLOG/>
      <Recentwork/>
      <Talktous/>
    <Footer/>
    </div>
  )
}

export default LifeScience
