import React from "react";
import "./ECommerceCaseStudies.css";
import img1 from "../ECommmerceCaseStudies/Assets/Ecom2.jpg";
import img2 from "../../Banking/BankCaseStudies/Assets/2.jpeg";

const ECommerceCaseStudies = () => {
  return (
    <div className="container-fluid container_CaseStudies" id="Case Studies">
      <div className="row">
        <div className="col-lg-12 Case_Studies ">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        <div className="col-lg-12 d-flex CaseStudies_Overall">
          <div className="Case_all">
            <img
              src={img1}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>
                Creating Customer Feedback Channels through Multiple Technology
                Platforms
              </h5>
              <p>
              Enriched customer feedback was made available to our clients to better map the processes keeping customer satisfaction in mind
              Different client verticals/departments can be gauged now based on the feedback available
              </p>
            </div>
          </div>
          <div className="Case_all">
            <img
              src={img2}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>
              Streamlining Non Delivery Returns

              </h5>
              <p>
              Working closely with our client to address the problem statement at hand, we did real time technology integration with our client’s delivery management panel using API stack
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ECommerceCaseStudies;
