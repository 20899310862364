import React from "react";
import "./DevopsCapabilities.css";
import img1 from "./Assets/img1.jpg";
import img2 from "./Assets/img2.jpg";
import img3 from "./Assets/img3.jpg";
import img4 from "./Assets/img4.jpg";

const DevopsCapabilities = () => {
  return (
    <div className="container-fluid " id="Capabilities">
      <div className="row">
        <div className="col-lg-12">
          <div>
            <h1>DEVOPS AND AGILE CAPABILITIES</h1>
          </div>
        </div>
        <div className="col-lg-12 Service_Content_01 my-5 ">
          <div className="col-lg-7 mx-3">
            <div className="Services_content01">
              <h3>ENTERPRISE AGILE TRANSFORMATION</h3>
              <p>
                We specialize in providing coaching and training services to
                organizations looking to adopt agile practices, enabling them to
                transform their businesses and achieve their goals. Our
                experienced coaches work closely with your teams to identify the
                specific needs of your organization and tailor our services
                accordingly. Our training programs cover a wide range of agile
                methodologies.
              </p>
              <hr />
              <ul className="icon_li">
                <p>Agile Transformation Services Include:</p>
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i> Assessment of
                  current maturity
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-laughing"></i> Develop an Agile
                  transformation Roadmap
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i> Provide Customized
                  training and learning materials – SAFe, Kanban
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i> Agile Lifecycle
                  Management Tools
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i>Senior Leadership
                  Coaching & Advisory
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 Image_service01">
            <img src={img1} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="col-lg-12 Service_Content_02 ">
          <div className="col-lg-5 Image_service02">
            <img src={img2} alt="" />
          </div>
          <div className="col-lg-7  ">
            <div className="Services_content02 ">
              <h3>AGILE SQUAD DELIVERY</h3>
              <p>
                Our agile squad delivery services enable organizations to
                benefit from the expertise of our skilled professionals who work
                closely with your teams to deliver outcomes that meet your
                specific business requirements. Our squads consist of scrum
                masters, business analysts, developers, testers, and other
                specialized roles needed for successful project delivery. We
                understand the importance of delivering quality results on time,
                and our agile squads are committed to achieving this by
                following industry best practices and leveraging the latest
                technologies. Optimum has delivered 100+ squads during the past
                12 months using Agile Delivery approach for delivering digital
                Solutions and Product Development.
              </p>
              <hr />
              <ul className="icon_li">
                <p>
                  Our clients benefit from our agile squad delivery services in
                  several ways, including –
                </p>
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i> Improved
                  Collaboration
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-laughing"></i> Enhanced Quality
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i>Reduced Costs
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i> Application
                  Maintenance Services
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-12 Service_Content_01 my-5">
          <div className="col-lg-7 mx-3">
            <div className="Services_content01">
              <h3>DEVOPS FOR DATA PROJECTS</h3>
              <p>
                Enable your enterprise data life cycle with DevOps maturity to
                reap benefits of higher efficiency and performance. Optimum
                Consulting’s DevOps experts help optimise end to end data
                projects.
                <br />
              </p>
              <hr />
              <ul className="icon_li">
                <p>Data DevOps services consist of:</p>
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i>
                  DevOps for Data Life cycle Projects
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-laughing"></i> DevOps for Machine
                  Learning Projects
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i>Agile Analytics
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i>Vendor and Tool Selection
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 Image_service01">
            <img src={img3} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="col-lg-12 Service_Content_02 ">
          <div className="col-lg-5 Image_service02">
            <img src={img4} alt="" />
          </div>
          <div className="col-lg-7  ">
            <div className="Services_content02 ">
              <h3>DATA OPS (MANAGED) SERVICES THE DATA LIFECYCLE</h3>
              <p>
                Data life cycle management can be overwhelming for any
                enterprise. Optimum Consulting can help with ongoing management
                and maintenance of the complete data lifecycle.
                <br />
              </p>
              <hr />
              <ul className="icon_li">
                <p>Optimum Consulting’s DataOps / Managed Service offerings:</p>
                <li className="list-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i>Realtime and Batch
                  ETL development
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-laughing"></i> Data Cleansing &
                  Quality
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i> Data Warehouse and
                  Security
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i> Cloud Analytics
                </li>
                <li className="list-group-item">
                  <i className="bi bi-emoji-smile"></i> KPI’s and Visualisations
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevopsCapabilities;
