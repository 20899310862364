import React from "react";
import "./GlobalService.css";

const GlobalService = () => {
  return (
    <div className="container" id="Services">
      <div className="row">
        <div className="col-lg-12 d-flex">
          <div className="col-lg-4 BankServices_Content">
            <h3>OUR GCC STORY</h3>
            <p>
              At LDAPP, we recognise the need for companies to open Global
              Capability Centers (GCC) in India and we engage with them on
              multiple fronts
            </p>
            <p>
              Clientele of 35+ in Fortune 500 list that have setup GCCs in India
            </p>
            <p>We run GCC as a separate line of business</p>
            <p>
              We fuse domain with technical capability and have extensive
              experience across clients in Banking, Capital Markets, Retail
              Banking, Manufacturing and Hi-tech
            </p>
            <p>
              We specialize in crafting right construct / engagement model based
              on the program needs of GCC. Few models are Squad Based Delivery,
              Outcome Based Delivery, Fixed Price, and T&M
            </p>
          </div>
          <div className="col-lg-8">
            <div className="d-flex">
              <div className="mobile_img1 py-5 col-lg-6">
                <h3 className="txt_01">CLIENTELE</h3>
                <p className="txt_02">
                  35+ Fortune 500 client
                  <br />
                </p>
              </div>
              <div className="mobile_img2 py-5 col-lg-6">
                <h3 className="txt_01">FOCUS AREA</h3>
                <p className="txt_02">
                  Fuse of Technology & Domain across Banking, Manufacturing &
                  Hi-Tech
                  <br />
                </p>
              </div>
            </div>
            <div className=" mobile_img3 py-5 col-lg-12">
              <h3 className="txt_01">CRAFTING RIGHT ENGAGEMENT</h3>

              <p className="txt_02">
                Squad Based Delivery, Outcome Based Delivery, Fixed Price, and
                T&M
                <br />
              </p>
            </div>
            <div className="d-flex">
              <div className="mobile_img4 py-5 col-lg-6">
                <h3 className="txt_01">EVERY EVOLVING BUSINESS NEEDS</h3>
                <p className="txt_02">
                  Sustainability (ESG), Global AML and Surveillance systems,
                  Risk Modelling, Generative AI Paradigms and models
                  <br />
                </p>
              </div>
              <div className="mobile_img5 py-5 col-lg-6">
                <h3 className="txt_01">ACCELERATE GROWTH</h3>
                <p className="txt_02">
                  Engage larger partner ecosystem to deliver innovative
                  solutions
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className=" BankServices_Content">
            <p>
              We have successfully delivered value across multiple GCC
              engagements and helped our partners gain a strategic foothold in
              Indian market for the past decade and the partnership continues to
              grow.
            </p>
            <p>
              Largest Provider of Financial Services in Malaysia with services
              across insurance, asset management, stock broking, nominee
              services, venture capital, leasing, and Internet banking
            </p>
            <p>
              One of the largest British Multinational Universal Bank engaged in
              Retail banking, Credit cards, Corporate and Investment banking,
              and Wealth management
            </p>
            <p>Leading Credit Bureau for Consumer and Business in Malaysia</p>
            <p>
              Best Digital Bank in Middle East with services across Retail,
              Corporate Finance, and Investment Advisory
            </p>
            <p>
              We are partners in the Digital Transformation journey and manage
              the ever-evolving transformation needs like Sustainability (ESG),
              Global AML and Surveillance systems, Risk Modelling, Building Hub
              for “as-a-service” transformation, Generative AI Paradigms and
              models to name a few
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalService;
