import React from "react";
import "./RetailService.css";

const RetailService = () => {
  return (
    <div className="container" id="Services">
      <div className="row">
        <div className="col-lg-12 d-flex">
          <div className="col-lg-4 BankServices_Content">
            <h3>DIGITAL SERVICES FOR RETAIL</h3>
            <p>
              LDAPP focuses on building the enhanced consumer experience
              through our innovative approach in delivering cloud-enabled
              platform solutions, business process automation and strategizing
              the omnichannel commerce strategy (right mix of stores, mobile,
              digital marketing and CRM capabilities)
            </p>
            <p>
              With our deep experience in Salesforce CRM enablement, cloud
              migration services and omnichannel enablement, we have enabled
              leading retail and marketplace enterprises to embrace digital
              disruption
            </p>
          </div>
          <div className="col-lg-8">
            <div className="d-flex">
              <div className="mobile_img1 py-5 col-lg-6">
                <h3 className="txt_01">SUPPLY CHAIN VISIBILITY & ANALYTICS</h3>
                <p className="txt_02">
                  Optimize operations, identify bottlenecks and inefficiencies,
                  and improve decision-making.
                  <br />
                </p>
              </div>
              <div className="mobile_img2 py-5 col-lg-6">
                <h3 className="txt_01">PRODUCTS AND SOLUTIONS</h3>
                <p className="txt_02">
                  Improve CX through Point-of-sale (POS) systems, mobile
                  applications, CRM and payment platforms
                  <br />
                </p>
              </div>
            </div>
            <div className=" mobile_img3 py-5 col-lg-12">
              <h3 className="txt_01">TESTING AND CX</h3>

              <p className="txt_02">
                Gather customer feedback and identify areas for improvement to
                improve customer satisfaction.
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetailService;
