import React from "react";
import "./PublicSectorOverview.css";

const PublicSectorOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="Content_Development10">
            <h1>PUBLIC SECTOR</h1>
            <p>Empowering Government for the Digital Age</p>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-lg-12 my-3">
          <div className="Button_Contain">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Services")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Services
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Case Studies")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Case Studies
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Connect")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Connect
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Blogs")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Blogs
            </button>
          </div>
        </div>
      </div>
      <div className="text-info">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-lg-12 my-5 Overview_content">
          <p>
            Governments all over the world are seeking to leverage technology to
            better serve the needs of citizens, to strengthen and empower
            communities and to enhance the way people live, work, play,
            interact, and even transact with one another.
          </p>
          <p>
            In addition to the specific use cases, IT and digital services can
            also support the broader goals of government entities by helping to
            improve transparency and accountability, and by enabling data-driven
            decision-making. With advanced analytics and data visualization
            tools, government entities can gain a deeper understanding of their
            operations and performance, which can help them identify areas for
            improvement and make more informed decisions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PublicSectorOverview;
