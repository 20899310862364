import React from "react";
import Header from "../../Components/Header/Header";
import HealthcareOverview from "../../Components/Industries/Healthcare/HealthcareOverview/HealthcareOverview";
import HealthcareService from "../../Components/Industries/Healthcare/HealthcareService/HealthcareService";
import HealthcareCaseStudies from "../../Components/Industries/Healthcare/HealthcareCaseStudies/HealthcareCaseStudies";
import HealthcareConnect from "../../Components/Industries/Healthcare/HealthcareConnect/HealthcareConnect";
import Footer from "../../Components/Footer/Footer";

const Healthcare = () => {
  return (
    <>
      <Header />
      <HealthcareOverview />
      <HealthcareService />
      <HealthcareCaseStudies />
      <HealthcareConnect/>
      <Footer/>
    </>
  );
};

export default Healthcare;
