import React from 'react'
import MobileOverview from '../../Components/Services/Mobile/MobileOverview/MobileOverview'
import MobileCapabilities from '../../Components/Services/Mobile/MobileCapabilities/MobileCapabilities'
import MobileTools from '../../Components/Services/Mobile/MobileTools/MobileTools'
import MobileCaseStudies from '../../Components/Services/Mobile/MobileCaseStudies/MobileCaseStudies'
import MobileBlogs from '../../Components/Services/Mobile/MobileBlogs/MobileBlogs'
import Header from "../../Components/Header/Header"
const Mobile = () => {
  return (
    <>
    <Header/>
    <MobileOverview/>
    <MobileCapabilities/>
    <MobileTools/>
    <MobileCaseStudies/>
    <MobileBlogs/>
    </>
  )
}

export default Mobile
