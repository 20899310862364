import React from "react";
import Header from "../../Components/Header/Header";
import RetailOverview from "../../Components/Industries/Retail/RetailOverview/RetailOverview";
import RetailService from "../../Components/Industries/Retail/RetailService/RetailService";
import RetailCaseStudies from "../../Components/Industries/Retail/RetailCaseStudies/RetailCaseStudies";
import RetailConnect from "../../Components/Industries/Retail/RetailConnect/RetailConnect";
import Footer from "../../Components/Footer/Footer";

const Retail = () => {
  return (
    <>
      <Header />
      <RetailOverview />
      <RetailService />
      <RetailCaseStudies/>
      <RetailConnect/>
      <Footer/>
    </>
  );
};

export default Retail;
