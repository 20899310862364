import React from "react";
import "./HealthcareService.css";

const HealthcareService = () => {
  return (
    <div className="container" id="Services">
      <div className="row">
        <div className="col-lg-12 d-flex">
          <div className="col-lg-4 BankServices_Content">
            <h3>Healthcare Software Solutions for Medical & Pharma Industry</h3>
            <p>
              the unique challenges that healthcare software companies face and
              helps them gradually embrace digital transformation in the digital
              industry without compromising on superior customer service,
              regulatory compliances, and the advent of non-traditional
              competitors. Our world-class digital team provides you with
              innovative services and solutions with access to a full spectrum
              of medical software solutions.
            </p>
            <p>
              Our pharmacy management systems assist administrators in
              efficiently processing, tracking, and dispensing all
              prescriptions. We develop comprehensive strategies that enable
              order management, batching and barcode scanning, inventory
              tracking, stock management, and data loading that help streamline
              management from POS to backend support.
            </p>
          </div>
          <div className="col-lg-8">
            <div className="d-flex">
              <div className="mobile_img1 py-5 col-lg-6">
                <h3 className="txt_01">Healthcare CRM Solutions</h3>
                <p className="txt_02">
                  The specialized technologies can be integrated with
                  care-delivery practices to connect doctors and patients at
                  every stage of the care delivery process
                  <br />
                </p>
              </div>
              <div className="mobile_img2 py-5 col-lg-6">
                <h3 className="txt_01">Patient Portal Management Solutions</h3>
                <p className="txt_02">
                  We create patient portals that electronically link patients
                  and healthcare professionals
                  <br />
                </p>
              </div>
            </div>
            <div className=" mobile_img3 py-5 col-lg-12">
              <h3 className="txt_01">Healthcare Analytics</h3>

              <p className="txt_02">
                LDAPP's Team helps develop scalable data warehouses, OLAP cubes,
                data mining algorithms, and data visualization tools that help
                in ready-to-use healthcare analytics that empower physicians to
                process medical data effectively
                <br />
              </p>
            </div>
            <div className="d-flex">
              <div className="mobile_img4 py-5 col-lg-6">
                <h3 className="txt_01">Teleradiology Services</h3>
                <p className="txt_02">
                  It offers high-value Picture Archiving and Communication
                  Systems (PACS) and Digital Imaging and Communications in
                  Medicine (DICOM)
                  <br />
                </p>
              </div>
              <div className="mobile_img5 py-5 col-lg-6">
                <h3 className="txt_01">Medical Image Analysis</h3>
                <p className="txt_02">
                  We develop software to support medical picture modalities,
                  improve early diagnosis, and lower the risk of medical errors
                  to meet research and clinical demands.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className=" BankServices_Content">
            <p>
              We deliver extensive SAP services and solutions to assist
              businesses in laying a solid foundation. Our professionals enable
              automated, insight-driven operations in four critical
              areas—Patient/Provider Engagement, Diagnosis and Care Delivery,
              Billing and Compliance, and Data Integration. They also support
              the transition to patient-centric services while coordinating
              workforces with strategic goals.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthcareService;
