import React from "react";
import "./Ourcase.css";
import img1 from "../../LifeScience/Ourcase/Assets/Images/001.jpeg";
import img2 from "../../LifeScience/Ourcase/Assets/Images/002.png";
import img3 from "../../LifeScience/Ourcase/Assets/Images/003.png";
import img4 from "../../LifeScience/Ourcase/Assets/Images/004.png";

const Ourcase = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 d-flex box_0001">
          <div className="col-lg-5 image_con1 py-3">
            <img src={img1} alt="" />
          </div>
          <div className="col-lg-7 con2">
            <div className="con2_text">
              <h4>Build A Unfield Tableau Dashboard Migrated From DOMO</h4>
              <p>
                Learn how we Re-engineered & Migrated DOMO dashboards to Tableau
                in Snowflake, reducing 80% of Compute Time & 45% of TCO
              </p>
              <p>
                Read Case Study <i class="bi bi-arrow-right"></i>
              </p>
            </div>
            <div className="logo_con d-flex">
              <img src={img2} alt="" />
              <img src={img3} alt="" />
              <img src={img4} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 overall_0001 d-flex">
            <div className="col-lg-6 content_001">
              <div>
                <h1>
                  Agilisium Builds FAIR Data Management Solutions for
                  Futureproof Life Sciences Industry
                </h1>
                <p>
                  The FAIR Data Principles are guidelines for scientific data
                  management and stewardship to make research data findable,
                  accessible, interoperable and reusable and thus, promote their
                  maximum use. Moreover, data that’s governed, trustworthy,
                  traceable and of high quality is critical for accelerating
                  self-service analytics and artificial intelligence (AI)
                  initiatives for many of our customers.
                </p>
              </div>
            </div>
            <div className="col-lg-6 content_002">
              <div className="d-flex">
                <div className="col-lg-6 box_001">
                  <i class="bi bi-clipboard-data-fill"></i>
                  <h4>Findable</h4>
                  <p>
                    Data are richly described by metadata and have a unique and
                    persistent identifier
                  </p>
                </div>
                <div className="col-lg-6 box_002">
                  <i class="bi bi-cloud-moon-fill"></i>
                  <h4>Accessible</h4>
                  <p>
                    Data and corresponding metadata are understandable to humans
                    and machines, and accessible through defined protocols
                  </p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-lg-6 box_003">
                  <i class="bi bi-cpu-fill"></i> <h4>Interoperable</h4>
                  <p>
                    Data and corresponding metadata use formal and accessible
                    knowledge representation to guarantee reuse
                  </p>
                </div>
                <div className="col-lg-6 box_004">
                  <i class="bi bi-device-hdd-fill"></i> <h4>Reusable</h4>
                  <p>
                    Metadata accurately describe the provenance and usage
                    license for the data
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ourcase;
