import React from 'react'
import "./MobileCapabilities.css"
import img1 from "../../Mobile/MobileCapabilities/Assets/Mb3.jpg";
import img2 from "../../Mobile/MobileCapabilities/Assets/Mb4.jpg";
import img3 from "../../Mobile/MobileCapabilities/Assets/mb2.jpg";


const MobileCapabilities = () => {
  return (
    <div className="container-fluid "id="Capabilities" >
    <div className="row">
      <div className="col-lg-12">
        <div>
          <h1 className='App1'>APP DEV & MAINTENANCE CAPABILITIES
          </h1>
        </div>
      </div>
      <div className="col-lg-12 Service_Content_01 my-5 ">
        <div className="col-lg-7 mx-3">
          <div className="Services_content01">
            <h3>ADVISORY SERVICES</h3>
            <p>
            Having a mobile application is like being in constant touch with your target segment. It allows your business to create a priceless connection with the user. A robust application empowers you to deliver a personalized and convenient user experience.            </p>
             <hr />
            <ul className="icon_li">
              <li className="list-group-item">
                <i className="bi bi-emoji-heart-eyes"></i> Well-versed with Modern Mobile Tech Stacks
              </li>
              <li className="list-group-item">
                <i className="bi bi-emoji-laughing"></i> Exploring the power of AI to build mobile apps.

              </li>
              <li className="list-group-item">
                <i className="bi bi-emoji-smile"></i> End-to-end Support from Ideation to Post-launch
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-4 Image_service01">
          <img src={img1} alt=""  className="img-fluid"/>
        </div>
      </div>
      <div className="col-lg-12 Service_Content_02 ">
        <div className="col-lg-5 Image_service02">
          <img src={img2} alt="" />
        </div>
        <div className="col-lg-7  ">
          <div className="Services_content02 ">
            <h3>CUSTOM APPLICATION DEVELOPMENT & MAINTENANACE</h3>
            <p>
            The LDAPP SOLUTIONS mobile app development team are core contributors to popular frameworks like React Native and Flutter. We understand the nuances of building a mobile app better than most.
            Collaborate with us today to develop a mobile app that is engaging and impactful.
            </p>
            <hr />
            <ul className="icon_li">
              <li className="list-group-item">
                <i className="bi bi-emoji-heart-eyes"></i>Cross-platform Development

              </li>
              <li className="list-group-item">
                <i className="bi bi-emoji-laughing"></i>Mobile App Design
</li>
              <li className="list-group-item">
                <i className="bi bi-emoji-smile"></i> App Maintenance and Support</li>
              <li className="list-group-item">
                <i className="bi bi-emoji-heart-eyes"></i> App Optimization
                <li className="list-group-item"></li>
              <i className="bi bi-emoji-heart-eyes"></i>Quality Assurance

              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-12 Service_Content_01 my-5">
        <div className="col-lg-7 mx-3">
          <div className="Services_content01">
            <h3>Mobile App Development Services
            </h3>
            <p>
              
            The  mobile app development team are core contributors to popular frameworks like React Native and Flutter. We understand the nuances of building a mobile app better than most.
            Collaborate with us today to develop a mobile app that is engaging and impactful.
            </p>
            <hr />
            <ul className="icon_li">
              <li className="list-group-item">
                <i className="bi bi-emoji-heart-eyes"></i>
                Quality Assurance and Software Testing Services
              </li>
              <li className="list-group-item">
                <i className="bi bi-emoji-laughing"></i> Legacy systems to
                UI/UX Design And Development Services
              </li>
              <li className="list-group-item">
                <i className="bi bi-emoji-smile"></i>Business Analysis Services
                
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-4 Image_service01">
          <img src={img3} alt=""  className="img-fluid" />
        </div>
      </div>
     
    </div>
  </div>
  )
}

export default MobileCapabilities
