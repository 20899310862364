import React from "react";
import "./InsuranceCaseStudies.css";

import img1 from "../InsuranceCaseStudies/Assets/InsuranceCase1.jpg"
const InsuranceCaseStudies = () => {
  return (
    <div className="container-fluid container_CaseStudies" id="Case Studies">
      <div className="row">
        <div className="col-lg-12 Case_Studies ">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        <div className="col-lg-12 d-flex CaseStudies_Overall">
          <div className="Case_all">
            <img
              src={img1}
              alt=""
              style={{ width: "60vh", height: "500px" }}
              className="image_1"
            />
            <div className="Case_overlay">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>
                ROBOTICS PROCESS AUTOMATION IN UNDERWRITING FOR AN INSURANCE
                COMPANY
              </h5>
              <p>
              looking to drive the process efficiency and business outcome in the Fleet handling of NB and application renewal processes. The process efficiency gained through RPA implementation was expected in the firm’s PIC partnership process and Claims processing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceCaseStudies;
